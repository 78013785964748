.error-page {
  &__wrap {

  }

  &__main {

  }

  &__info {

  }

  &__title {
    -webkit-text-stroke-width: 0;
  }

  &__subtitle {
    color: $text-color-transparent;
  }

  &__image {
    display: flex;
    justify-content: center;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {

  }
}

@include respond-up('large') {
  .error-page {
    &__wrap {
      padding-top: 103px;
    }

    &__main {
      margin-top: 140px;
    }

    &__info {
      flex: 0 0 355/1500*100vw;
    }

    &__image {
      flex: 0 0 538/1500*100vw;

      svg {
        height: 168/1500*100vw;
        width: 100%;
      }
    }

    &__subtitle {
      margin-top: 35px;
      font-size: 17px;
      line-height: 30/17*1em;
    }

    &__buttons {
      margin-top: 82px;
    }
  }
}

@include respond-up('medium') {
  .error-page {
    &__main {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .error-page {
    &__main {
      margin-top: 80px;
    }

    &__info {
      flex: 0 0 244/768*100vw;
    }

    &__image {
      flex: 0 0 378/768*100vw;
    }

    &__subtitle {
      margin-top: 26px;
    }

    &__buttons {
      margin-top: 56px;
    }
  }
}

@include respond-down('medium') {
  .error-page {
    &__wrap {
      padding-top: 68px;
    }

    &__subtitle {
      font-size: 15px;
      line-height: 24/15*1em;
    }
  }
}

@include respond-down('small') {
  .error-page {
    &__main {
      margin-top: 38px;
    }

    &__image {
      margin-top: 56px;
      height: 103/375*100vw;
      width: 100%;
    }

    &__subtitle {
      margin-top: 15px;
    }

    &__buttons {
      margin-top: 52px;
    }
  }
}