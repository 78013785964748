
.qr{
  display: flex;
  flex-direction: column;
  align-items: center;
  &__img{
    background: #fff;
    margin-bottom: 20px;
    border-radius: 16px;
    width: 100%;
    max-width: 329px;
  }
  &__subtitle{
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    br{
      &.desktop{
        display: block;
      }
      &.mobile{
        display: none;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .qr{
    &__subtitle{
      max-width: 241px;
      br{
     &.desktop{
       display: none;
     }
        &.mobile{
          display: block;
        }
      }
    }}
}




