.category {
  transform: translateY(50px);
  opacity: 0;
  transition: {
    delay: .7s;
    duration: 1.35s;
    property: opacity, transform;
  };

  &__wrap {

  }

  &__items {

  }

  &__item {
    position: relative;
  }

  &__trigger{
    position: absolute;
    left: 0;
    width: 0;
    &_top{
    }
    &_bottom{
    }
  }
}

@include respond-up('large') {
  .category {
    &__wrap {
      margin-top: 110px;
    }

    &__items {
      margin: 0 -90/1500*100vw;
    }

    &__item {
      padding: 0 90/1500*100vw;
    }

    &__trigger{
      &_top{
        top: -110px;
        height: calc(43vw + 110px);
      }
      &_bottom{
        top: 43vw;
        height: 100vh;
      }
    }
  }
}

@include respond-up('medium') {
  .category {
    &__items {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      flex: 0 0 50%;
    }
  }
}

@include respond('medium') {
  .category {
    &__wrap {
      margin-top: 70px;
    }

    &__items {
      margin: 0 -30/768*100vw;
    }

    &__item {
      padding: 0 30/768*100vw;
    }
    &__trigger{
      &_top{
        top: -70px;
        height: 100vh;
      }
      &_bottom{
        top: calc(100vh - 70px);
        height: 100vh;
      }
    }
  }
}

@include respond-down('small') {
  .category {
    &__wrap {
      margin-top: 40px;
    }
    &__trigger{
      &_top{
        top: -40px;
        height: 100vh;
      }
      &_bottom{
        top: calc(100vh - 40px);
        height: 100vh;
      }
    }
  }
}