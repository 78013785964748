.news {

  &__item {
    display: flex;
    border-bottom: 1px solid $table-border-color;

  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__date {
    color: $primary-color;
    font-weight: 500;
  }

  &__title {
    font-weight: 500;
  }

  &__text {
    color: $text-color-transparent;
  }
}

@include respond-up('large') {
  .news {
    &__top {
      padding-top: 100px;
    }

    &__item {
      padding: 100px 150/1500*100vw 87px;
      justify-content: space-between;
      transition: $default-transition;

      &:hover {
        background-color: #1C2456;
        transition: $default-transition;

        .news__button {
          transition: $default-transition;

          g {
            fill: $primary-color;
          }
        }
      }
    }

    &__image-wrapper {
      width: 384/1500*100vw;
      height: 256/1500*100vw;
    }

    &__item-wrapper {
      position: relative;
      flex: 0 0 60%;
      padding: 20px 150px 0 0;
    }

    &__date {
      margin-bottom: 29px;
      font-size: 14px;
      line-height: 16px;
    }

    &__title {
      margin-bottom: 20px;
      font-size: 23px;
      line-height: 33px;
    }

    &__text {
      max-width: 500px;
      font-size: 17px;
      line-height: 30px;

    }

    &__button {
      position: absolute;
      display: block;
      right: 0;
      top: 50%;
      transform: translate(-50%);
      transition: $default-transition;

      svg {
        g {
          fill: $t;
          transition: $default-transition;
        }
      }
      &:hover {
        svg {
          g {
            fill: $primary-color;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .news {

    &__top {
      padding-top: 60px;
    }

    &__item {
      padding: 70px 39/768*100vw 50px;
    }

    &__image-wrapper {
      width: 205/768*100vw;
      height: 136/768*100vw;
    }

    &__item-wrapper {
      padding-left: 40px;
    }

    &__date {
      margin-bottom: 13px;
      font-size: 14px;
      line-height: 16px;
    }

    &__title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 28px;
    }

    &__text {
      max-width: 500px;
      font-size: 15px;
      line-height: 27px;

    }

    &__button {
      display: none;
    }
  }
}

@include respond-down('small') {
  .news {

    &__top {
      padding-top: 40px;
    }

    &__item {
      flex-direction: column;
      padding: 40px 17/375*100vw 25px;
    }

    &__image-wrapper {
      margin-bottom: 20px;
      width: 100%;
      height: 217/375*100vw;
    }

    &__item-wrapper {
      padding-left: 0;
    }
  }
}