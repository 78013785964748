.catalog-menu {

}

@include respond-up('large') {
  .catalog-menu {
    &__items {
      margin-top: 120px;
    }
  }
}

@include respond('medium') {
  .catalog-menu {
    &__items {
      margin-top: 60px;
    }
  }
}

@include respond-down('small') {
  .catalog-menu {
    &__main {
      overflow: scroll;
    }

    &__items {
      margin-top: 40px;
    }
  }
}