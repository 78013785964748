
.counter {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #393F67;
  height: 56px;
  border-radius: 12px;

  &__input {
    width: 40px;
    text-align: center;
    color: #fff;
    font-weight: 500;
  }

  &__btn {
    transition: 0.3s ease all;
    background: transparent;
    display: flex;
    height: 30px;
    width: 30px;
    align-content: center;
    justify-content: center;
    align-items: center;

    svg {
      path, rect {
        transition: 0.3s ease all;
      }
    }

    &:hover {
      svg {
        path, rect {
          fill: #E73C5F;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .counter {
    width: 100px;
    height: 48px;

    &__input {
      width: 16px;;
    }

    &__btn {
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

}
