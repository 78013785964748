.floors {

  &__list {
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    padding-bottom: 19px;
    margin-bottom: 50px;
    :not(:last-child) {
      margin-right: 30px;
    }
    opacity: 0;
    transform: translateY(20px);
    transition: {
      delay: 1.4s;
      property: opacity, transform;
      duration: 1.14s;
    }
  }

  &__floor {
    font-size: 30px;
    display: inline-block;
  }

  &__label {
    &_active {
      border-bottom: 2px solid;
      padding-bottom: 20px;
      border-spacing: 15px;
      color: $primary-color;
    }
  }

  &__slider {
    position: relative;
    display: flex;
    justify-content: center;
    opacity: 0;
    transform: translateY(40px);
    transition: {
      delay: 1.75s;
      property: opacity, transform;
      duration: 1.4s;
    }
  }

  &__slide {
    position: absolute;
    opacity: 0;
    transition: opacity 1s;
    z-index: 90;

    &_active {
      opacity: 1;
      z-index: 100;
    }
  }

  &__mark {
    position: absolute;
    margin-left: -18px;
    margin-top: -18px;
  }

  &__mark-label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(231, 62, 93, 1);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 20px;
    box-shadow: 0 0 0 3px rgba(231, 62, 93, 0.33);
    cursor: default;
    z-index: 100;
    &:hover + .floors__mark-text {
      z-index: 110;
      opacity: 1;
    }
  }

  &__mark-text {
    position: relative;
    display: inline-block;
    font-size: 17px;
    padding: 20px;
    background-color: #FFFFFF;
    color: #000000;
    left: -40%;
    top: 5px;
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 90;
  }

  &.animate{
    .floors{
      &__list, &__slider{
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}


@include respond-up('large') {
  .floors {
    &__list {
      margin-bottom: 138px;
    }

    &__slider {
      margin-bottom: 170px;
    }
  }
}

@include respond-down('medium') {
  .floors {
    &__list {
      margin-bottom: 70px;
    }

    &__slider {
      margin-bottom: 74px;
    }
  }
}

@include respond-down('small') {
  .floors {

    &__list {
      margin-bottom: 49px;
    }

    &__slider {
      margin-bottom: 55px;
      overflow-x: scroll;
      overflow-y: hidden;

      justify-content: start;
    }

    &__slide {
      height: 404px;
      overflow-y: hidden;
      &_active {

      }
    }

    &__image {
      height: 404px;
      max-width: none;

    }
  }
}