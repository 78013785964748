.feedback {
  z-index: 500;
  position: relative;
  background-color: $background-color;

  &__wrapper{
    background-color: #5627AC;
  }

  &__main {
    display: flex;

    &.animate {
      .feedback {
        &__info,
        &__form {
          transform: translate(0);
          opacity: 1;
        }
      }
    }
  }

  &__info,
  &__form {
    opacity: 0;
  }

  &__info {
    transform: translateX(40px);
    transition-property: opacity, transform;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.52, .15, .25, .8);
  }

  &__form {
    transform: translateY(60px);
    transition: opacity .95s ease .3s, transform .7s ease-out;
  }

  &__subtitle {
    color: $text-color-transparent;
  }

  &__title{
    -webkit-text-stroke-width: 0;
  }
}

@include respond-up('large') {
  .feedback {
    padding-bottom: 56/1500*100vw;

    &__main {
      padding-top: 92px;
    }

    &__info {
      padding: 34px 100/1500*100vw 34px 0;
    }

    &__subtitle {
      max-width: 370px;
      margin-top: 35px;
      font-size: 17px;
      line-height: 30/17*1em;
    }

    &__form {
      flex: 0 0 623/1500*100vw;
      margin-bottom: -56/1500*100vw;
    }
  }
}

@include respond-up('medium') {
  .feedback {
    &__main {
      justify-content: space-between;
      padding-top: 60px;
    }
  }
}

@include respond('medium') {
  .feedback {
    padding-bottom: 52/768*100vw;

    &__main {
      padding-top: 60px;
    }

    &__info {
      padding: 8px 20/768*100vw 20/768*100vw;
    }

    &__subtitle {
      max-width: 370px;
      margin-top: 25px;
    }

    &__form {
      flex: 0 0 380/768*100vw;
      margin-bottom: -52/768*100vw;
    }
  }
}

@include respond-down('medium') {
  .feedback {
    &__subtitle {
      font-size: 15px;
      line-height: 24/15*1em;
    }
  }
}

@include respond-down('small') {
  .feedback {
    overflow: hidden;
    padding-bottom: 96px;

    &__main {
      flex-direction: column;
      padding-top: 40px;
    }

    &__info {
      margin-bottom: 30px;
    }

    &__subtitle {
      margin-top: 15px;
    }

    &__form {
      margin-bottom: -96px;
    }
  }
}