


.sectionBlock{
  &__title{
    font-size: 20px;
    line-height: 28px;
  }
  &__body{
    grid-template-columns: 100%;
    display: grid;
    margin-top: 12px;
    grid-gap: 12px;
  }
}
@media screen and (max-width: 767px) {
  .sectionBlock{
&__title{
  font-size: 18px;
  line-height: 26px;
}
  }
}

