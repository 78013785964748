$button-size: 102px;
$button-size-mobile: 92px;

.button {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: 2px solid $secondary-color;
  border-radius: 100px;
  background: $t;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: $default-transition;

  &:hover {
    background-color: $secondary-color;
  }

  &_square {
    border-radius: 0;
    height: auto !important;
    width: auto !important;
    line-height: normal !important;
    flex-basis: 56px !important;
  }

  &_dark {
    color: #000;

    &:hover {
      color: #fff;
    }
  }

  &_light {
    color: #fff;
    transition: $default-transition;
  }
}

@include respond-up('large') {
  .button {
    flex: 0 0 $button-size;
    width: $button-size;
    height: $button-size;
    font-size: 11px;
    line-height: $button-size;
    &_square {
      flex-basis: 44px !important;
    }
  }
}

@include respond-down('medium') {
  .button {
    flex: 0 0 $button-size-mobile;
    width: $button-size-mobile;
    height: $button-size-mobile;
    font-size: 10px;
    line-height: $button-size-mobile;
    &_square {
      flex-basis: 36px !important;
    }
  }
}