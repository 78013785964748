.product-card {
  &__link {
    display: flex;
    flex-direction: column;
  }

  &__image {
    display: flex;
    overflow: hidden;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform .7s ease-in-out;
  }

  &__info {
    display: block;
  }

  &__title {
    display: inline-block;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
  }

  &__description {
    display: block;
    margin-top: 12px;
    color: $text-color-opacity;
  }
}

@include respond-up('large') {
  .product-card {
    &__image {
      height: 645/1500*100vw;

      &:hover {
        img {
          transform: scale(1.06);
        }
      }
    }

    &__info {
      padding-bottom: 110px;
    }

    &__title {
      position: relative;
      padding-top: 34px;
      font-size: 25px;
      line-height: 32/25*1em;

      &:hover {
        &::before {
          animation: slitherFrom .4s cubic-bezier(.71, .17, .12, .8), slitherTo .4s cubic-bezier(.67, .14, .11, .83) .4s;
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: $primary-color;
        transform: scaleX(0);
      }
    }

    &__description {
      font-size: 17px;
      line-height: 30/17*1em;
    }
  }
}

@include respond('medium') {
  .product-card {
    &__image {
      height: 400/768*100vw;
    }

    &__info {
      padding-bottom: 70px;
    }
  }
}

@include respond-down('medium') {
  .product-card {
    &__info {
    }

    &__title {
      padding-top: 25px;
      font-size: 22px;
      line-height: 30/22*1em;
    }

    &__description {
      font-size: 15px;
      line-height: 26/15*1em;
    }
  }
}

@include respond-down('small') {
  .product-card {
    transform: translateY(0) !important;

    &__image {
      height: 430/375*100vw;
    }

    &__info {
      padding-bottom: 40px;
    }
  }
}

@keyframes slitherFrom {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }

  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}

@keyframes slitherTo {
  0% {
    transform: scaleX(1);
    transform-origin: right;
  }

  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}