


.guestRow {
  font-family: 'TT Norms';
    &__firstColumn{
        max-width:calc(100% - 135px)
    }
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
      align-items: center;
      position: relative;
      &--disabled{
          opacity: 0.2;
          cursor: not-allowed;
          &:after{
              content:"";
              bottom: -8px;
              top: -8px;
              left: -8px;
              right: -8px;
              border-radius: 12px;
              position: absolute;
              //background: rgba(255, 255, 255, 0.1);
          }
      }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7F7F94;
  }

  &__dr {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    background: #393F67;
    border-radius: 12px;

  }


}

.checkBox {
  position: relative;
  display: block;
  border: 2px solid rgba(255, 255, 255, 0.24);
  border-radius: 24px;
  width: 42px;
  height: 22px;
  position: relative;
  transition: 0.5s ease all;
  cursor: pointer;
    &.disabled{
        opacity: 0.5;
    }

  &__circle {
    position: absolute;
    transition: 0.5s ease all;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: #E73C5F;
    top: 50%;
    transform: translateY(-50%);
    left: 2px
  }

  &.select {
    border: 2px solid #E73C5F;
    background: #E73C5F;
    .checkBox {
      &__circle {
        background: #fff;
        left: auto;
        right: 2px;
      }
    }
  }

}

hr {
  border: none;
  border-bottom: 1px solid #393F67;
  margin-top: 16px;
  margin-bottom: 16px;
}

.tooltipInfo {
  position: relative;
  line-height: 20px;
  margin-left: 6.5px;
  display: inline-flex;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
    .guestRow{
        &__firstColumn{
            max-width:45%
        }
        &__title{
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 0;
        }
        &__dr{
            margin-top: 12px;
        }
    }
}
