.contacts {
  &__title {
  }

  &__info {
    display: flex;
    transform: translateY(30px);
    opacity: 0;
    transition: {
      delay: 1.4s;
      property: opacity, transform;
      duration: 1.14s;
    }
  }

  &__info-block {
    padding-top: 22px;
    display: flex;
    flex-direction: column;
    position: relative;


    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 3px;
      width: 35px;
      background-color: $primary-color;
    }

    &_second {
      &::before {
        background-color: $secondary-color;
      }
    }
  }

  &__head {
    font-size: 21px;
    line-height: 31/21*1em;
    margin-bottom: 8px;
  }

  &__body {
    font-size: 15px;
    color: rgba(255,255,255,0.60);
    line-height: 35/15*1em;
  }

  &__socials {

  }

  &__map {
    transform: translateY(50px);
    opacity: 0;
    transition: {
      delay: .7s;
      duration: 1.35s;
      property: opacity, transform;
    };
  }

  &__footer {

  }

  &.animate{
    .contacts{
      &__info{
        transform: translateY(0);
        opacity: 1;
      }
      &__map{
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

@include respond-up('large') {
  .contacts {
    position: relative;
    padding-bottom: 60px;
    &__title {
      padding-bottom: 80px;
    }

    &__info-block {
      margin-right: 180px;
      padding-bottom: 83px;
      &_second {
        flex-grow: 1;
        margin-right: 0;
      }
    }

    &__map {
      height: 672/1500*100vw;
      overflow: hidden;
      z-index: 10;
      position: relative;
    }

    &__footer {
      height: 160px;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: #5627AC;

    }
  }
}

@include respond('medium') {
  .contacts {
    position: relative;
    padding-bottom: 10px;
    &__title {
      padding-bottom: 50px;
    }

    &__info {
      flex-wrap: wrap;
    }

    &__info-block {
      flex: 0 0 50%;
      padding-bottom: 40px;
      &_second {
        flex: 0 0 50%;
        flex-grow: 1;
        margin-right: 0;
      }
    }

    &__socials {
      flex: 0 0 100%;
      margin-bottom: 40px;
    }

    &__map {
      height: 386/768*100vw;
      overflow: hidden;
      z-index: 10;
      position: relative;
    }

    &__footer {
      height: 110px;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: #5627AC;
    }
  }
}

@include respond-down('small') {
  .contacts {
    position: relative;
    padding-bottom: 0;
    &__title {
      padding-bottom: 40px;
    }

    &__info {
      flex-direction: column;
    }

    &__info-block {
      padding-bottom: 30px;
      &_second {
        padding-bottom: 40px;
      }
    }

    &__socials {
      margin-bottom: 40px;
    }

    &__map {
      height: 400/375*100vw;
      overflow: hidden;
      z-index: 10;
      position: relative;
    }

    &__footer {
      height: 74px;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: #5627AC;
    }
  }
}