.packet {
  &__top {
    padding: 15px;
  }

  &__wrap-name {
    color: $t;
    font-weight: bold;
    font-size: 21px;
    line-height: 31/21*1em;
    text-align: center;
    text-transform: uppercase;
  }

  &__table {
    table {
      width: 100%;
      background-color: #fff;
      border-collapse: collapse;
      color: $text-color;
      font-size: 15px;
      line-height: 26/15*1em;

      td {
        &:not(:last-child) {
          border-right: 1px solid $table-border-color;
        }

        h5 {
          font-size: 16px;
          color: #000;
          line-height: 20/16*1em;
        }
      }

      thead {
        background: $table-head-bg;

        td {
          padding-top: 14px;
          padding-bottom: 13px;
        }
      }

      tbody {
        td {
          h5 {
            padding-bottom: 12px;
          }

          strong {
            font-weight: 500;
            color: #000;
            font-size: 21px;
            line-height: 30/21*1em;
          }

          em {
            font-weight: 500;
            color: $text-color;
            font-size: 18px;
            line-height: 20/18*1em;
            text-decoration: line-through;
          }
        }

        tr {
          &:not(:last-child) {
            border-bottom: 1px solid $table-border-color;
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .packet {
    min-width: calc(100% - #{$indent-large} * 2);

    &__table {
      table {
        td {
          padding-left: 28px;
          padding-right: 28px;
        }

        tbody {
          td {
            padding-top: 16px;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .packet {
    &__wrap-name {
      width: calc((100vw - #{$indent-medium} * 2) - 30px);
    }
  }
}

@include respond-down('medium') {
  .packet {
    &__table {
      table {
        td {
          padding-left: 20px;
          padding-right: 10px;
          min-width: 240px;

          &:first-child {
            min-width: 160px;
          }
        }

        tbody {
          td {
            padding-top: 16px;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .packet {
    &__wrap-name {
      width: calc((100vw - #{$indent-small} * 2) - 30px);
    }
  }
}