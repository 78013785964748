.details {
  opacity: 0;
  transform: translateY(40px);
  transition: {
    property: opacity, transform;
    delay: 0.2s;
    duration: 1.5s;
  };

  &.animate {
    opacity: 1;
    transform: translateY(0);
  }

  &__title {
    color: #000;
    font-weight: bold;
  }

  &__text {
    color: $text-color;
  }

  &__video {
    display: flex;
    position: relative;
  }

  &__video-preview {

  }

  &__video-play {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 2px solid $secondary-color;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__filter {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0B093D;
    opacity: 0.48;
  }
}

@include respond-up('large'){
  .details {
    padding-bottom: 120/1500*100vw;
    width: 700/1500*100vw;

    &__title {
      font-size: 35px;
      line-height: 52px;
      padding-bottom: 26px;
    }

    &__text {
      font-size: 17px;
      line-height: 30px;
      padding-bottom: 49px;
      width: 600/1500*100vw;
    }

    &__video {

    }

    &__video-preview {

    }

    &__video-play {
    }
  }
}

@include respond-up('medium'){
  .details {
    &__title {

    }

    &__text {

    }

    &__video {

    }

    &__video-preview {

    }

    &__video-play {
      width: 92px;
      height: 92px;
      transition: background-color 0.4s ease-in-out;

      &:hover {
        background-color: $secondary-color;
      }
    }
  }
}

@include respond('medium'){
  .details {
    padding-bottom: 60/768*100vw;

    &__title {
      font-size: 28px;
      line-height: 38px;
    }

    &__text {
      padding-bottom: 30px;
    }

    &__video {

    }

    &__video-preview {

    }

    &__video-play {

    }
  }
}

@include respond-down('medium'){
  .details {
    &__title {
      padding-bottom: 20px;
    }

    &__text {
      font-size: 15px;
      line-height: 27px;
    }

    &__video {

    }

    &__video-preview {

    }

    &__video-play {

    }
  }
}

@include respond-down('small'){
  .details {
    padding-bottom: 40/375*100vw;

    &__title {
      font-size: 25px;
      line-height: 35px;
    }

    &__text {
      padding-bottom: 25px;
    }

    &__video {

    }

    &__video-preview {

    }

    &__video-play {
      width: 64px;
      height: 64px;
    }
  }
}