.content-header{
  .title{
    transform: translateX(57px);
    opacity: 0;
    transition: {
      property: opacity, transform;
      duration: 1.5s;
      delay: .7s;
    };
  }

  &.animate{
    .title{
      transform: translateX(0);
      opacity: 1;
    }
  }
}