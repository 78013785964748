

.calendar {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    button {

    }

  }

  &__nextMonth, &__prevMonth {
    background: #393F67;
    border-radius: 8px;
    border: none;
    color: #7F7F94;
    svg {
      transition: 0.3s ease all;
    }

    &:hover {
      background: #353b60;
      color: #E73C5F;

      svg {
        fill: #E73C5F
      }
    }
  }

  &__title {
    font-family: 'TT Norms';
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-transform: capitalize;
  }

  &__matrix {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-row-gap: 14px;
    align-items: center;
    justify-content: space-between;

    &__item {
      width: 42px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease all;
      font-family: 'TT Norms';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      border-radius: 8px;
      &--btn {
        color:#393F67;
      }
      &--btnDisabled{
        background: #393F67;
        color:#7F7F94 ;
      }
      &--btnActive{
        background: #262D5B;
        color: #fff;
        border: 2px solid #DFE2E3;
        &:hover,&.checked{
          color: #E73C5F;
          border:  2px solid  #E73C5F;
        }
      }


    }
  }
}
@media screen and (max-width: 768px) {
  .calendar {
    &__head {
      button {
      }
    }
    &__nextMonth, &__prevMonth {
      svg {
      }
      &:hover {
        svg {
        }
      }
    }

    &__title {
      font-size: 18px;
      line-height: 26px;
    }

    &__matrix {


      &__item {
        width: 32px;
        height: 32px;
      }}}
}

