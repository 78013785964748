.product-additional{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__list{
  }

  &__item{
    width: 100%;
    border-bottom: 1px dashed rgba(63, 39, 44, 0.42);
    padding-bottom: 15px;

    &._active{
      .product-additional{
        &__description-wrapper{
          //height: 100%;
        }
        &__link-icon{
          #Rectangle-Copy{
            opacity: 0;
          }
        }
      }
    }
  }

  &__link{
    display: block;
    position: relative;
  }

  &__link-text{
    font-weight: 500;
    color: black;
  }

  &__link-icon{
    position: absolute;
    right: 9px;

    #Rectangle-Copy{
      transition: {
        property: opacity;
        duration: .2s;
      };
    }
  }

  &__description-wrapper{
    height: 0;
    overflow: hidden;
    transition: {
      duration: 1s;
      property: height;
    };
  }

  &__description{
    font-size: 17px;
    color: $text-color;
    line-height: 30/17*1em;

    ul{
      padding-left: 29px;

      li{
        position: relative;

        &::before{
          content: '';
          position: absolute;
          width: 5px;
          height: 5px;
          background-color: $secondary-color;
          left: -29px;
          top: 12px;
          border-radius: 10px;
        }
      }
    }
  }
}

@include respond-up('large'){
  .product-additional{

    &__list{
      flex: 0 0 528/1500*100vw;
    }

    &__item{
      padding-bottom: 15px;

      &:first-child{
        border-top: 1px dashed rgba(63, 39, 44, 0.42);
      }
    }

    &__link{
      padding: 41px 33px 23px 2px;
    }

    &__link-icon{
      top: 42px;
    }

    &__description{
      font-size: 17px;
      line-height: 30/17*1em;
      padding-bottom: 27px;

      ul{
        padding-left: 29px;

        li{
          position: relative;

          &::before{
            left: -29px;
            top: 12px;
          }
        }
      }
    }
  }
}

@include respond-up('medium'){
  .product-additional{
    &__link-text{
      font-size: 23px;
      line-height: 20/23*1em;
    }
  }
}

@include respond('medium'){
  .product-additional{
    &__item{
      padding-bottom: 5px;
    }

    &__link{
      padding: 29px 35px 25px 2px;
    }

    &__link-icon{
      top: 35px;
    }

    &__description{
      padding-bottom: 25px;

      ul{
        padding-left: 34px;

        li{
          &::before{
            left: -34px;
          }
        }
      }
    }
  }
}

@include respond-down('medium'){
  .product-additional{
    flex-wrap: wrap;

    &__list{
      flex: 0 0 100%;

      &:first-child{
        border-top: 1px dashed rgba(63, 39, 44, 0.42);
      }
    }

    &__link-icon{
      top: 30px;
    }

    &__description{
      font-size: 15px;
      line-height: 26/15*1em;

      ul{
        li{
          &::before{
            top: 11px;
          }
        }
      }
    }
  }
}

@include respond-down('small'){
  .product-additional{
    &__item{
      padding-bottom: 3px;
    }

    &__link{
      padding: 28px 23px 24px 2px;
    }

    &__link-text{
      font-size: 21px;
      line-height: 20/21*1em;
    }

    &__description{
      padding-bottom: 27px;

      ul{
        padding-left: 29px;

        li{
          &::before{
            left: -29px;
          }
        }
      }
    }
  }
}