.widget {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  z-index: 0;
  pointer-events: none;
  transition: $default-transition;
  max-height: 100%;
  overflow: hidden;

  &._open {
    z-index: 3000;
    opacity: 1;
    pointer-events: auto;
  }

  &__container {
    overflow-y: scroll;
  }
}

@include respond-up('medium') {
  .widget {
    align-items: center;

    &__container {
      margin-top: 70px;
      max-height: 70vh;
    }

    &__main {
      width: 500px;
    }
  }
}

@include respond-down('small') {
  .widget {
    padding-top: 72px;

    &__container {
      width: 100%;
      max-height: 100vh;
    }
  }
}