.about-team {
  &__list {
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    padding-bottom: 19px;
    margin-bottom: 50px;

    :not(:last-child) {
      margin-right: 30px;
    }
  }

  &__section {
    font-size: 30px;
    display: inline-block;
  }

  &__label {
    &_active {
      border-bottom: 2px solid;
      padding-bottom: 20px;
      border-spacing: 15px;
      color: $primary-color;
    }
  }

  &__slider {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__slide {
    width: 100%;
    position: absolute;
    display: grid;
    grid-template-columns: 28% 28% 28%;
    grid-template-rows: auto auto 0 0 0 0 0;
    grid-column-gap: 8%;
    grid-row-gap: 80px;
    opacity: 0;
    transition: opacity 1s;
    //top: -100vh;
    z-index: -10;

    &_active {
      opacity: 1;
      //position: relative;
      //display: grid;
      grid-template-rows: auto;
      z-index: 100;
      transform: translateY(0);
    }
  }

  &__teammate {
  }
  &__teammate-inner {
    height: 100%;
    padding-top:0;
    display: flex;
    flex-direction: column;
  }

  &__teammate-image {
    width: auto;
    height: 100%;
    max-width: unset;
  }

  &__teammate-image-block {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }


  &__teammate-name {
    color: #ffffff;
  }

  &__teammate-profession {
    color: $text-color-opacity;
  }

  &__trigger{
    position: absolute;
    left: 0;
    width: 0;
    &_top{
    }
    &_bottom{
    }
  }
  &__detail{
    margin-top: 24px;

  }

}

@include respond-up('large') {
  .about-team {
    padding-bottom: 110px;

    &__title {
      font-size: 100px;
      margin-bottom: 116px;
    }

    &__list {
      margin-bottom: 110px;
    }

    &__teammate {
    }

    &__teammate-name {
      font-size: 19px;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    &__teammate-profession {
      font-size: 15px;
    }

    &__trigger{
      &_top{
        top: -10px;
        height: calc(43vw + 10px);
      }
      &_bottom{
        top: 50vw;
        height: 100vh;
      }
    }


    &__teammate-image-block {
      height: 28vw;
    }
  }
}

@include respond-down('medium') {
  .about-team {
    padding-bottom: 60px;

    &__title {
      font-size: 60px;
      margin-bottom: 60px;
    }

    &__list {
      margin-bottom: 70px;
    }

    &__teammate {

    }

    &__teammate-name {
      font-size: 16px;
      padding-top: 12px;
      padding-bottom: 6px;
    }

    &__teammate-profession {
      font-size: 14px;
    }

    &__trigger{
      &_top{
        top: -70px;
        height: 100vh;
      }
      &_bottom{
        top: calc(100vh - 70px);
        height: 100vh;
      }
    }

    &__teammate-image-block {
      height: 30vw;
    }
  }
}

@include respond-down('small') {
  .about-team {
    padding-bottom: 50px;

    &__title {
      font-size: 35px;
      margin-bottom: 40px;
    }

    &__list {
      margin-bottom: 40px;
    }

    &__teammate {
      //&:nth-child(3n+2) {
      //  padding-top:0;
      //}
    }
    &__teammate-inner{
      transform: translateY(0) !important;
    }

    &__teammate-name {
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 6px;
    }

    &__teammate-profession {
      font-size: 14px;
    }

    &__slide {
      grid-template-columns: 48% 48%;
      grid-column-gap: 4%;
      grid-row-gap: 30px;
    }

    &__label {
      &_active {
        border-bottom: 2px solid;
        padding-bottom: 0px;
        border-spacing: 15px;
        color: $primary-color;
      }
    }

    &__trigger{
      &_top{
        top: -40px;
        height: 100vh;
      }
      &_bottom{
        top: calc(100vh - 40px);
        height: 100vh;
      }
    }

    &__teammate-image-block {
      height: 53vw;
    }
  }
}