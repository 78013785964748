.about-posts {

  display: grid;


  &__number {
    font-weight: bold;
    padding-bottom: 40px;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $primary-color;
    color: $background-color;
  }

  &__title {
    font-size: 23px;
    font-weight: 500;
  }

  &__description {
  color: rgba(255,255,255,0.60);
    font-size: 15px;
    line-height: 24px;
  }

}

@include respond-up('large') {
  .about-posts {
    grid-template-columns: 46% 46%;
    grid-column-gap: 8%;
    grid-row-gap: 90px;
    padding-bottom: 170px;

    &__title {
      padding-bottom: 24px;
    }

    &__number {
      font-size: 70px;
    }

    &__description {
      font-size: 17px;
      line-height: 30px;
    }
  }
}

@include respond-down('medium') {
  .about-posts {
    grid-row-gap: 50px;
    padding-bottom: 70px;

    &__post-container{
      display: flex;
    }

    &__post {
      margin-left: 30px;
    }

    &__title {
      padding-bottom: 20px;
    }

    &__number {
      font-size: 60px;
      line-height: 60px;
    }
  }
}

@include respond-down('small') {
  .about-posts {

    padding-bottom: 40px;

    &__title {
      padding-bottom: 14px;
    }

    &__number {
      font-size: 50px;
      line-height: 50px;
    }
  }
}