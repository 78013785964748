
.tooltip {
  transition: 0.3s ease all;
  &.fade {
    &-enter-from, &-leave-to {
      opacity: 0;
    }
  }

  z-index: 2;
  opacity: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: block;
  position: absolute;
  left: 50%;
  top: calc(100% + 15px);
  transform: translateX(-50%);
  background: rgba(14, 14, 14, 0.8);
  border-radius: 12px;
  text-align: center;
  padding: 14px;
  width: 250px;
  color: #fff;

  &:after {
    content: '';
    border: 10px solid transparent;
    top: -20px;
    position: absolute;
    border-bottom: 10px solid rgba(14, 14, 14, 0.8);
    transform: translateX(-50%);
    left: 50%;
  }
}

