.product-advantages{

  &.animate{
    .product-advantages{
      &__item{
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  &__item{
    position: relative;
    opacity: 0;
    transform: translateY(20px);
    transition: {
      property: opacity, transform;
      duration: .6s;
    };

    @for $i from 1 through 15{
      &:nth-child(#{$i}){
        transition-delay: calc(.31s * (#{$i} - 1));
      }
    }


  }

  &__number{
    position: absolute;
    font-size: 15px;
    color: $secondary-color;
    line-height: 16/15*1em;
    font-weight: bold;
    left: 0;
    top: 0;
  }

  &__title{
    font-size: 23px;
    color: white;
    line-height: 20/23*1em;
    font-weight: 500;
  }

  &__description{
    font-size: 17px;
    color: $text-color-opacity;
    line-height: 30/17*1em;
  }
}

@include respond-up('large'){
  .product-advantages{
    &__item{
      padding-left: 62px;
      margin-bottom: 94px;
    }

    &__title{
      margin-bottom: 16px;
    }


    &__description{
      font-size: 17px;
      line-height: 30/17*1em;
    }

  }
}

@include respond-up('medium'){
  .product-advantages{

  }
}

@include respond('medium'){
  .product-advantages{
    &__item{
      padding-left: 60px;
      margin-bottom: 40px;
    }
    &__title{
      margin-bottom: 18px;
    }
  }
}

@include respond-down('medium'){
  .product-advantages{
    &__description{
      font-size: 15px;
      line-height: 27/15*1em;
    }
  }
}

@include respond-down('small'){
  .product-advantages{
    &__item{
      padding-left: 37px;
      margin-bottom: 28px;
    }

    &__title{
      margin-bottom: 14px;
    }
  }
}