
.paymentControl {
  position: relative;
  overflow: hidden;
  padding: 14px 20px;
  border: 2px solid #DFE2E3;
  cursor: pointer;
  width: 100%;
  background: #fff;
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  transition: 0.3s ease all;

  &--checked {
    background: #FDF0F3;
    border: 2px solid #E73C5F;
    color: #E73C5F;
  }

  &:hover {

    border: 2px solid #E73C5F;
  }

  &__caption {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  &__logo {
    display: flex;
  }

  &__check {
    transition: 0.3s ease all;
    position: absolute;
    display: flex;
    opacity: 0;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    &--show {
      opacity: 1;
    }
  }

  &__title {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .paymentControl {
    &__caption{
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
    }
  }
}


