
.rules {
  background: #272F64;
  padding: 150px 0;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 62px;
    line-height: 74px;
    text-transform: uppercase;
  }

  &__body {
    margin-top: 36px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;

    ol {
      list-style: decimal;

      li {
        font-weight: 500;
        font-size: 18px !important;
        line-height: 26px;

      }
    }

    p {
      margin-top: 26px
    }

  }
}

@media screen and (max-width: 767px) {
  .rules {
    padding: 48px 20px;

    .container {
      padding: 0;
    }

    &__title {
      line-height: 30px;
      font-size: 24px;

    }

    &__body {

      margin-top: 17px;
      font-size: 16px;
        line-height: 22px;

      ol {
        padding-left: 28px;

          li{
            font-size: 16px;
            line-height: 22px;
        }

      }
    }

  }
}
