.indicators{
  &__list{
    width: 100%;
  }

  &__item{
    width: 100%;
    display: flex;
    margin-bottom: 23px;
    align-items: flex-end;

    @for $i from 1 through 15 {
      &:nth-child(#{$i}){
        .indicators{
          &__progress{
            transition-delay: calc(2.4s + (#{$i} * .2s));
          }
        }
      }
    }
  }

  &__name-block{
    flex: 0 0 192px;
    width: 192px;
    margin-right: 25px;
  }

  &__value{
    flex: 1 1 auto;
    white-space: nowrap;
    font-size: 15px;
    color: white;
    font-weight: 500;
    line-height: 16/15*1em;
  }

  &__name{
    opacity: 0.61;
    font-size: 16px;
    color: #A2A5B8;
    line-height: 1em;
  }

  &__line{
    margin: 7px 0 5px;
    background-color: rgba(255, 255, 255, 0.14);
    height: 2px;
    width: 100%;
  }

  &__progress{
    height: 100%;
    transition: {
      duration: .6s;
      timing-function: cubic-bezier(0, 0, 0.58, 1);
    };
  }
}