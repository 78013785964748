.zones {
  overflow: hidden;

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    color: transparent;

    &_transparent {
      display: block;
      transform: translateX(10%);
      transition: {
        property: transform;
        duration: 1.65s;
        timing-function: cubic-bezier(0.52, 0.15, 0.25, 0.8);
      }
    }

    &_white {
      display: block;
      color: white;
      transform: translateX(10%);
      transition: {
        property: transform;
        delay: .3s;
        duration: 1.47s;
        timing-function: cubic-bezier(0.52, 0.15, 0.25, 0.8);
      }
    }
  }

  &__body {
    display: flex;
  }

  &__text-block {
  }

  &__inner {
    display: flex;
    flex-direction: column;

  }

  &__image-wrapper {
    position: relative;
    height: 509/1500*100vw;
    flex: 0 0 368/1500*100vw;
    transform: translateX(140px);
    transition: {
      delay: .3s;
      timing-function: cubic-bezier(0.52, 0.15, 0.25, 0.8);
      property: transform;
      duration: 1.5s;
    }
  ;
  }

  &__sub-description {
    color: #80849D;
  }

  &__button {

  }

  &__image-list {

  }

  &__image-item {

  }

  &__image-image {
    background: {
      size: cover;
      repeat: no-repeat;
      position: center;
    }
  }

  &.animate {
    .zones {
      &__title {
        &_transparent {
          transform: translateX(0);
        }
        &_white {
          transform: translateX(0);
        }
      }
      &__image-wrapper {
        transform: translateX(0);
      }
    }
  }
}

@include respond-up('large') {
  .zones {
    padding: 312/1500*100vw 0 180/1500*100vw;

    &__title {
      font-size: 90/1500*100vw;
      line-height: 104/90*1em;
      margin-bottom: 80/1500*100vw;
    }

    &__body {
      position: relative;

    }

    &__text-block {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }

    &__description {
      font-size: 30/1500*100vw;
      line-height: 45/30*1em;
      margin-bottom: 65/1500*100vw;
      max-width: 650/1500*100vw;
      color: #80849D;
      flex: 0 0 auto;

      a {
        color: white;
        border-bottom: 2px solid;
        transition: $default-transition;
      }
    }

    &__wrapper {
      flex: 1 1 auto;
    }

    &__inner {
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    &__sub-description {
      font-size: 17/1500*100vw;
      line-height: 30/17*1em;
      margin-bottom: 32px;
      max-width: 500/1500*100vw;
    }

    &__button {
      display: flex;
      justify-content: flex-end;
      margin-right: 164/1500*100vw;
    }

    &__image-wrapper {
    }

    &__image-list {
      width: 519/1500*100vw;
      margin-right: -10vw;
      height: 100%;
      position: relative;
      overflow: hidden;

      &_mobile {
        display: none;
      }
    }

    &__image-item {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      opacity: 0;
      width: 100%;
      z-index: 5;
      transition: opacity .8s cubic-bezier(.25, .1, .25, .9);

      &._active {
        opacity: 1;
        z-index: 10;
        //transition: opacity 1.4s cubic-bezier(.74, .11, .31, .88);
      }
    }

    &__image-image {
      width: 100%;
      height: 100%;
    }
  }
}


@include respond-down('medium') {
  .zones {
    &__inner {
      flex: 0 0 314/768*100vw;
      margin-right: 44px;
    }
  }
}

@include respond-down('medium') {
  .zones {
    padding: 150/768*100vw 0 70/768*100vw;

    &__title {
      font-size: 50px;
      line-height: 60/50*1em;
      margin-bottom: 30px;
    }

    &__body {
      position: relative;
      flex-direction: column;
    }

    &__image-wrapper {
      display: none;
    }

    &__description {
      font-size: 20px;
      line-height: 30/20*1em;
      margin-bottom: 30px;
      color: white;
    }

    &__sub-description {
      font-size: 15px;
      line-height: 28/15*1em;
      margin-bottom: 40px;
    }

    &__button {
      display: flex;
      justify-content: flex-end;
    }

    &__image-list {
      display: flex;

      &_desktop {
        display: none;
      }
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__inner {
      //margin-right: 49px;
      //flex: 0 0 50%;
      //min-height: 300px;
    }

    &__image-item {
      display: none;
      &._active {
        display: block;
      }
    }

    &__image-image {
      max-height: 300px;
    }
  }
}

@include respond-down('small') {
  .zones {
    padding: 100/375*100vw 0 40/375*100vw;
    &__title {
      font-size: 30/375*100vw;
      line-height: 40/30*1em;
      margin-bottom: 17/375*100vw;
      max-width: 292/375*100vw;
    }

    &__inner {
      order: 2;
      margin: 0;
    }

    &__inner {

    }

    &__wrapper {
      flex-direction: column;
    }

    &__sub-description {
    }

    &__button {
      justify-content: center;
    }

    &__image-image {
      max-height: 100%;
      width: 100%;
      margin-bottom: 35px;
    }
  }
}