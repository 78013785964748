.footer {
  &._hidden{
    z-index: 0;
  }
  &__policy-data {
    grid-area: personal_data;

    span {
      font-size: 14px;
      color: $text-color-transparent;
      transition: $default-transition;
      border-bottom: 1px solid rgba(255, 255, 255, 0.22);
    }

    &:hover {
      span {
        border-bottom: 1px solid white;
        color: #fff;
      }
    }
  }

  &__inner {
    display: grid;
  }

  &__menu-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__made-label-wrapper {
    grid-area: made;
    display: flex;
  }

  &__socials {
    grid-area: socials;
  }

  &__mail-block {
    grid-area: mail-block;
  }

  &__info-block {
    grid-area: info-block;
  }

  &__menu-link {
    transition: $default-transition;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 2.18px;
    line-height: 12/12*1em;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    white-space: nowrap;
  }

  &__label {
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 2px;
    line-height: 12/11*1em;
    margin-bottom: 16px;
    color: rgba(255, 255, 255, 0.60);
  }

  &__info-block {
    display: grid;
    color: rgba(255, 255, 255, 0.60);
  }

  &__copyright {
    grid-area: copyright;
    display: flex;
    font-size: 14px;
    white-space: nowrap;
  }

  &__visiting-rules {
    grid-area: rules;
    display: flex;
    align-items: flex-end;

    span {
      font-size: 14px;
      color: $text-color-transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.22);
      transition: $default-transition;
      margin-left: 9px;
    }

    &:hover {
      span {
        border-bottom: 1px solid white;
        color: #fff;
      }
    }
  }

  &__politics {
    grid-area: policy;

    span {
      font-size: 14px;
      color: $text-color-transparent;
      transition: $default-transition;
      border-bottom: 1px solid rgba(255, 255, 255, 0.22);
    }

    &:hover {
      span {
        border-bottom: 1px solid white;
        color: #fff;
      }
    }
  }

  &__made-label {
    color: rgba(255, 255, 255, 0.60);
    margin-right: 2px;
    font-size: 14px;
  }

  &__block-value {
    display: block;
    white-space: nowrap;
  }

  &__made-link {
    border-bottom: 1px solid transparent;
    transition: $default-transition;
    font-size: 14px;
    color: #fff;

    &:hover {
      border-bottom: 1px solid white;
    }
  }

  &__mail-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__socials {
    display: flex;
    align-items: flex-end;
  }

  &__app-link-block {
    grid-area: app;
  }

  &__app-link-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__app-link {
    display: flex;

    &:first-child {
      margin-right: 8px;
    }
  }

  &__treaty {
    grid-area: treaty;
  }

  &__treaty-second {
    grid-area: treaty-second;
  }

  &__menu-list {
    grid-area: menu-list;
  }

  &__phone-block {
    grid-area: phone-block;
  }

  &__address-block {
    grid-area: address-block;
  }
}

@include respond-up('large') {
  .footer {
    padding: 110px 0 76px;

    &__policy-data {
      white-space: nowrap;
    }

    &__inner {
      grid-row-gap: 66px;
      padding-right: 26px;
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas:
        'menu-list menu-list menu-list . phone-block address-block'
        'app app app . mail-block socials'
        'info-block info-block info-block info-block info-block info-block';
    }

    &__label {
      padding-top: 4px;
    }

    &__menu-list {
      margin: 0 -14px;
    }

    &__menu-link {
      padding: 12px 14px;
      margin: -12px 0 10px;

      &::before {
        position: absolute;
        content: '';
        bottom: 6px;
        height: 2px;
        right: 14px;
        left: 14px;
        transition: background-color .2s ease-in-out;
      }

      &:hover {
        &::before {
          background-color: $secondary-color;
        }
      }
    }

    &__address-block {
      justify-self: end;
    }

    &__info-block {
      //grid-template-columns: auto 150/1500*100vw auto 275/1500*100vw;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 47px;
      grid-template-areas:
       'rules . policy policy policy . '
       'treaty . personal_data personal_data personal_data . '
       'treaty-second . copyright copyright copyright made';
    }

    &__copyright {
      grid-area: copyright;
    }

    &__visiting-rules {
      white-space: nowrap;
    }

    &__politics {
      white-space: nowrap;
    }

    &__mail-block {
      padding-right: 30px;
      align-self: flex-start;
    }

    &__socials {
      display: flex;
      justify-content: flex-end;
      align-self: center;
      &_tab {
        display: none;
      }
    }

    &__made-label-wrapper {
      justify-content: flex-end;
    }

    &__copyright,
    &__politics,
    &__policy-data {
      transform: translateX(-47px);
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__visiting-rules,
    &__treaty,
    &__treatysecond{
      margin-bottom: 25px;
    }

    &__politics {
      margin-bottom: 25px;
    }
  }
}

@include respond('medium') {
  .footer {
    padding: 73px 0 80px;
    &__treaty,
    &__treaty-second{
      align-items: flex-start;
    }

    &__policy-data {
      margin-bottom: 25px;
    }

    &__inner {
      grid-row-gap: 49px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
              'menu-list menu-list menu-list'
              'phone-block address-block mail-block'
              'app app socials'
              'info-block info-block info-block';
    }

    &__phone-block {
      margin-right: 69px;
    }

    &__made-label-wrapper {
      text-align: left;
    }

    &__info-block {
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 22px;
      //grid-template-areas:
      //'rules policy'
      //'treaty personal_data'
      //'treaty-second .'
      //'copyright made';
    }

    &__politics {
      display: flex;
      justify-content: flex-start;
    }

    &__socials {
      display: none;
      &_tab {
        display: flex;
        grid-column: 1/4;
      }
    }
    &__visiting-rules,
    &__politics {
      grid-row: 1;
    }

    &__treaty,
    &__policy-data {
      grid-row: 2;
    }

    &__treaty-second {
      grid-row: 3;
    }

    &__visiting-rules,
    &__treaty,
    &__treaty-second {
      grid-column: 1/5;
      white-space: nowrap;
      align-items: center;
    }

    &__politics,
    &__policy-data {
      grid-column: 7/13;
      transform: translateX(-22px);
    }

    &__visiting-rules,
    &__treaty,
    &__treaty-second,
    &__politics,
    &__policy-data {
      margin-bottom: 0;
    }

    &__treaty,
    &__treaty-second,
    &__policy-data {
      margin-top: 22px;
    }

    &__copyright {
      grid-column: 4/10;
    }

    &__made-label-wrapper {
      grid-column: 11/13;
      white-space: nowrap;
    }

    &__socials_tab,
    &__copyright,
    &__made-label-wrapper {
      grid-row: 4;
      align-items: center;
      margin-top: 45px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__menu-link {
      padding: 15px 20px;
    }

    &__menu-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;
    }
  }
}

@include respond-down('small') {
  .footer {
    padding: 56px 0 46px;

    &__inner {
      overflow: hidden;
      grid-row-gap: 40px;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
              'menu-list menu-list'
              'phone-block address-block '
              'mail-block socials'
              'app app'
              'info-block info-block';
      @media screen and (max-width: 375px) {
        grid-template-areas:
                'menu-list menu-list'
                'phone-block address-block '
                'mail-block .'
                'socials .'
                'app app'
                'info-block info-block';
      }
      @media screen and (max-width: 350px) {
        grid-template-areas:
                'menu-list menu-list'
                'phone-block .'
                'address-block .'
                'mail-block .'
                'socials .'
                'app app'
                'info-block info-block';
      }
    }

    &__app-link-wrapper {
      @media screen and (max-width: 350px) {
        flex-direction: column;
      }
    }

    &__app-link {
      @media screen and (max-width: 350px) {
        &:first-child {
          margin-right: 0;
          margin-bottom: 8px;
        }
      }
    }

    &__menu-list {
      justify-content: space-between;
    }

    &__block-value {
      font-size: 15px;
      letter-spacing: 0;
    }

    &__info-block {
      grid-template-areas:
      'rules'
      'treaty'
      'treaty-second'
      'policy'
      'personal_data'
      'copyright'
      'made';
      grid-gap: 20px;
    }

    &__made {
      display: flex;
      justify-content: center;
    }

    &__socials {
      align-items: center;
      &_tab {
        display: none;
      }
    }
  }
}