.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2000;
  transition: {
    property: opacity, transform;
    duration: .85s;
    delay: 1.6s;
  };

  &__3d-link,
  &__3d-link-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
  }

  &.need-animation {
    transform: translateY(-25px);
    opacity: 0;
    transition: {
      property: none;
    };
  }

  &__background {
    transition: {
      duration: .3s;
      property: background-color;
      timing-function: ease-in-out;
    };
  }

  &__container {
    transition: {
      property: padding;
      duration: .3s;
      timing-function: ease-in-out;
    };
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: {
      property: height;
      duration: .3s;
      timing-function: ease-in-out;
    };
  }

  &__logo {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 605;
    width: 100px;
    display: flex;
    justify-content: center;
    left: calc(50% - 50px);

    svg {
      height: 100%;
      width: auto;
    }
  }

  &__phone-block {
    display: flex;
    position: relative;
    z-index: 600;
  }

  &__phone {

  }

  &__phone-text {
    color: white;
    font-weight: 500;
  }

  &__hamburger {

    z-index: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .header__hamburger-inner {
        width: 100%;
      }
    }

    &._open {
      .header {
        &__hamburger-line {
          width: 0;
        }

        &__hamburger-closer {
          opacity: 1;
          transition-delay: .6s;
        }
      }

      &:hover svg {
        #blue-1, #blue-2 {
          width: 29px;
        }
      }
    }
  }

  &__hamburger-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 38px;
  }

  &__hamburger-closer {
    opacity: 0;
    transition: {
      property: opacity;
      delay: 0s;
      duration: .4s;
    };

    svg {
      #blue-1, #blue-2 {
        width: 0;
        transition: {
          duration: .3s;
          property: width;
        };
      }

      #blue-2 {
        transition-delay: .1s;
      }
    }
  }

  &__hamburger-line {
    height: 2px;
    background-color: white;
    width: 100%;
    position: absolute;
    right: 0;
    transition: {
      property: width;
      duration: .4s;
      timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    };

    &:nth-child(1) {
      top: 6px;
      transition-delay: .2s;
    }

    &:nth-child(2) {
      bottom: 6px;

      .header__hamburger-inner {
        transition-delay: .2s;
      }
    }
  }

  &__hamburger-inner {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    background-color: #2AADE8;
    transition: {
      duration: .4s;
      property: width;
      timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    };
  }

  &._sticky {
    .header {
      &__background {
        background-color: $background-color;
      }
    }
  }

  &__menu-list,
  &__menu-item,
  &__menu-link {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@include respond-up('large') {
  .header {
    &__container {
      padding: 19px 80/1500*100vw 0;
    }

    &__3d-link-wrapper {
      flex: 1 0 auto;
      padding-right: 270/1920*100vw;
    }

    &__inner {
      height: 84px;
    }

    &__menu-list {
      flex: 1 0 auto;
      display: flex;
      margin: 0 -10px;
      align-items: center;
    }

    &__menu-link {
      padding: 2px 10px;
      font-weight: 500;
      font-size: 12px;
      color: white;
      letter-spacing: 2.18px;
      text-transform: uppercase;
      line-height: 15/12*1em;
      position: relative;

      &::before {
        transition: background-color .2s ease-in-out;
        content: '';
        position: absolute;
        bottom: 20px;
        height: 2px;
        left: 10px;
        right: 10px;
      }

      &._active, &:hover {
        &::before {
          background-color: $secondary-color;
        }
      }
    }

    &__lighting {
      //flex: 1 1 auto;
      padding-right: 54/1500*100vw;
    }

    &__phone-block {
      justify-content: flex-end;
      padding-right: 59/1500*100vw;
      flex: 1 1 auto;
    }

    &__phone {
    }

    &__phone-text {
      font-size: 15px;
      letter-spacing: 1px;
      line-height: 17/15*1em;
    }

    &__phone-icon {
    }

    &._sticky {
      .header {
        &__inner {
          height: 70px;
        }

        &__container {
          padding: 13px 100/1500*100vw 13px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .header {
    &__phone-icon {
      display: none;
    }
  }
}

@include respond('medium') {
  .header {
    &__3d-link-wrapper {
      padding-left: 32/838*100vw;
    }

    &__container {
      padding: 10px 39/768*100vw;
    }

    &__inner {
      height: 46px;
    }

    &__logo {
    }

    &__phone-text {
      color: white;
      font-size: 13px;
      line-height: 15/13*1em;
      letter-spacing: 0.87px;
    }
  }
}

@include respond-down('medium') {
  .header {
    &__menu-list {
      display: none;
    }

    &__lighting {
      display: none;
    }

    &__3d-link-wrapper {
      order: 2;
      flex: 1 1 auto;
    }

    &__hamburger {
      order: 3;
    }

    &__phone-block {
      order: 1;
    }

    &__phone,
    &__hamburger {
      padding: 15px;
      margin: -15px;
    }
  }
}

@include respond-down('small') {
  .header {
    &__3d-link-wrapper {
      padding-left: 21/375*100vw;
    }

    &__container {
      padding: 10px 22/375*100vw 0;
    }

    &__inner {
      height: 57px;
    }

    &__logo {
    }

    &__phone-text {
      display: none;
    }

    &__phone-icon {
      display: block;
    }

    &._sticky {
      .header {
        &__inner {
          height: 37px;
        }

        &__container {
          padding: 7px 22/375*100vw 7px;
        }
      }
    }
  }
}