.catalog-head {
  overflow: hidden;
  &__title{
    transform: translateX(57px);
    opacity: 0;
    transition: {
      property: opacity, transform;
      duration: 1.5s;
      delay: .7s;
    };
  }

  &__description {
    color: $text-color-transparent;
    transform: translateX(60px);
    opacity: 0;
    transition: {
      property: opacity, transform;
      delay: 1.42s;
      duration: 1.5s;
    };
  }

  &.animate{
    .catalog-head{
      &__title{
        transform: translateX(0);
        opacity: 1;
      }

      &__description{
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

@include respond-up('large') {
  .catalog-head {
    &__info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__title {
      line-height: 1em;
    }

    &__description {
      flex: 0 0 510/1500*100vw;
      margin-top: 6/1500*100vw;
      font-size: 21px;
      line-height: 32/21*1em;
    }
  }
}

@include respond('medium') {
  .catalog-head {
    &__description {
      margin-top: 30px;
      font-size: 18px;
      line-height: 28/18*1em;
    }
  }
}

@include respond-down('small') {
  .catalog-head {
    &__description {
      margin-top: 18px;
      font-size: 16px;
      line-height: 26/16*1em;
    }
  }
}