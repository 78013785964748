.nav-tabs {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &::before,
  &::after {
    content: '';
  }

  ul {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid rgba(255, 255, 255, .14);

    li {
      margin-bottom: -1px;

      &:first-child {
        a {
          padding-left: 0;
        }
      }

      &:last-child {
        a {
          padding-right: 0;
        }
      }

      &._active {
        a {
          color: $primary-color;

          span {
            border-bottom: 2px solid $primary-color;
          }
        }
      }

      a {
        display: block;
        color: #fff;

        &:hover {
          color: $primary-color;
        }

        span {
          display: block;
          border-bottom: 2px solid $t;
          color: inherit;
          white-space: nowrap;
          transition: color .4s ease-in-out;
        }
      }
    }
  }
}

@include respond-up('large') {
  .nav-tabs {
    &::before,
    &::after {
      flex: 0 0 $indent-large;
    }

    ul {
      flex-wrap: wrap;

      li {
        a {
          padding: 18px 18px 0;

          span {
            padding-bottom: 28px;
            font-size: 30px;
            line-height: 26/30*1em;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .nav-tabs {
    &::before,
    &::after {
      flex: 0 0 $indent-medium;
    }

    ul {
      li {
        a {
          padding: 0 16px;

          span {
            padding-bottom: 24px;
            font-size: 24px;
            line-height: 26/24*1em;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .nav-tabs {
    &::before,
    &::after {
      flex: 0 0 $indent-small;
    }

    ul {
      li {
        a {
          padding: 0 12px;

          span {
            padding-bottom: 12px;
            font-size: 17px;
            line-height: 26/17*1em;
          }
        }
      }
    }
  }
}