.price-nav {
  &__items {

  }

  &__item {

  }

  &__link {

  }

  &__name {

  }
}

@include respond-up('large') {
  .price-nav {
    &__items {
      display: flex;
      flex-wrap: wrap !important;;
    }

    &__item {
      padding-bottom: 15px;
    }
  }
}