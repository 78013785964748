.layout {
  position: relative;
  z-index: 401;
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;

  &__parallax-container{
    position: relative;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);

    &.transition{
      transition: {
        property: height;
        duration: .3s;
      };
    }
  }

  &__parallax-shadow{
    background-color: black;
    pointer-events: none;
    position: absolute;
    z-index: 70;
    opacity: 0;
    top: 0;
    right: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100);
  }

  &__parallax-back{
    position: fixed;
    left: 0;
    right: 0;
    z-index: 30;
    top: 0;
    height: calc(var(--vh, 1vh) * 100);
  }

  &__base{
    z-index: 50;
    position: relative;
    background-color: $background-color;
  }

  &__wrapper{
    z-index: 300;
    transition: {
      property: background-color;
      duration: .2s;
      timing-function: ease-in-out;
    };

    &._white{
      background-color: white;
    }
  }

  &__footer{
    background-color: $background-color;
    z-index: 400;
    position: relative;
  }
}

