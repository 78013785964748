.features-map{
  height: 100%;
  width: 100%;

  &__item{
    position: absolute;
    &:hover{
      z-index: 30;
      .features-map{
        &__content{
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__link{
    border-radius: 100px;
    background-color: #222C39;
    display: block;
  }

  &__icon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 50;

  }

  &__sizeble{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(34, 44, 57, 0.33);
    border-radius: 100px;

    animation-name: pulsar;
    animation-iteration-count: infinite;
    animation-duration: 3.6s;
  }

  &__content{
    position: absolute;
    width: 311px;
    background-color: white;
    padding: 20px 10px 22px 20px;
    pointer-events: none;
    opacity: 0;
    transition: {
      duration: .3s;
      timing-function: ease-in-out;
      property: opacity, transform;
    };

    &._left{
      right: 0;
    }
    &._right{
      left: 0;
    }
    &._top{
      bottom: 54px;
      transform: translateY(-7px);
    }
    &._bottom{
      top: 54px;
      transform: translateY(7px);
    }

  }

  &__title{
    font-size: 17px;
    color: black;
    line-height: 22/17*1em;
    margin-bottom: 11px;
  }

  &__description{
    font-size: 15px;
    color: $text-color;
    line-height: 23/15*1em;
  }
}

@include respond-up('large'){
  .features-map{
    &__link{
      height: 38px;
      width: 38px;
    }
    &__content{
      &._top{
        bottom: 54px;
      }
      &._bottom{
        top: 54px;
      }
    }
    &__icon{
      svg{
        flex: 0 0 11px;
      }
    }
  }
}

@include respond-up('medium'){
  .features-map{

  }
}

@include respond('medium'){
  .features-map{

  }
}

@include respond-down('medium'){
  .features-map{
    &__link{
      height: 30px;
      width: 30px;
      svg{
        flex: 0 0 8px;
      }
    }
    &__content{
      &._top{
        bottom: 44px;
      }
      &._bottom{
        top: 44px;
      }
    }
  }
}

@include respond-down('small'){
  .features-map{

  }
}

@keyframes pulsar {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  16% {
    opacity: 1;
  }

  33% {
    transform: scale(1.6);
    opacity: 0;
  }

  34% {
    transform: scale(1);
  }
  36% {
    opacity: 1;
  }

  53% {
    opacity: 1;
  }

  70% {
    transform: scale(1.6);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}