.lighting {
  &__inner {
    display: flex;
    align-items: center;
  }
  &__label {
    font-size: 13px;
    color: rgba(240, 240, 240, 0.60);
    letter-spacing: 0;
    line-height: 20/13*1em;
    flex: 0 0 auto;
    margin-right: 13px;
  }

  &__lights {
    display: flex;
    margin: 0 -5px;
    align-items: center;
  }

  &__light {
    height: 9px;
    flex: 0 0 9px;
    border-radius: 50px;
    margin: 0 5px;

    &._active {
      flex: 0 0 14px;
      height: 14px;
    }

    &_green {
      border: 1px solid #51AD32;

      &._active {
        box-shadow: 0 0 12px 0 rgba(89, 182, 37, 0.83);
        background-color: #51AD32;
      }
    }

    &_yellow {
      border: 1px solid #FFC213;

      &._active {
        box-shadow: 0 0 12px 0 rgba(255, 194, 19, 0.83);
        background-color: #FFC213;
      }
    }

    &_red {
      border: 1px solid $secondary-color;

      &._active {
        box-shadow: 0 0 12px 0 rgba(231, 60, 95, 0.83);
        background-color: $secondary-color;
      }
    }
  }
}