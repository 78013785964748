.breadcrumbs {
    &__list {
    display: flex;
    font-size: 15px;
    color: rgba(255,255,255,0.42);
    line-height: 1em;
    transition: {
      delay: .7s;
      duration: 1.5s;
      property: opacity, transform;
      timing-function: cubic-bezier(0.52, 0.15, 0.25, 0.8);

    };
    transform: translateX(57px);
    opacity: 0;

    &.animate{
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__item {

    &:nth-child(4n+2) {
      color: $delimiter-yellow;
    }

    &:nth-child(4n+4) {
      color: $primary-color;
    }

    &_delimiter {
      padding: 0 10px;
    }
  }

  &__link {

  }
}

@include respond-up('large') {
  .breadcrumbs {
    padding: 187px 0 35px;
  }
}

@include respond('medium') {
  .breadcrumbs {
    padding: 120px 0 18px;
  }
}

@include respond-down('small') {
  .breadcrumbs {
    padding: 98px 0 16px;
  }
}