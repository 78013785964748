.catalog-layout {
   &__title{

  }

  &__menu {
    opacity: 0;
    transform: translateY(20px);
    transition: {
      delay: 1.4s;
      property: opacity, transform;
      duration: 1.14s;
    }
  }

  &__description{

  }

  &__content {

  }

  &.animate{
    .catalog-layout{
      &__menu{
        opacity: 1;
        transform: translateY(0);
      }
      &__content{
        .category{
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}