
.otherError {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #E1002E;
  margin-top: 24px;
}

.timer {
  margin-top: 5.208vw;
  font-size: 26px;
  line-height: 34px;
  font-weight: 500;

  span {
    color: #E73C5F;
  }
}

.ticketController {
  display: grid;
  grid-template-columns:repeat(6, 1fr);
  grid-gap: 1.25vw;
  transition: 0.3s linear all;
  margin-top: 48px;
  grid-template-areas:
    'f f s s t t';

  &:deep(.card) {
    &:nth-child(1) {
      grid-area: f;
    }

    &:nth-child(2) {
      grid-area: s;
    }

    &:nth-child(3) {
      grid-area: t;
    }
  }

  &__info {
    margin-top: 24px;

    li {
      padding-left: 26px;
      position: relative;
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      color: #7F7F94;

      &:last-child {
        margin-bottom: 0px;
      }

      &:before {
        left: 0;
        top: 0;
        position: absolute;
        content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2.5C8.64583 2.5 7.38281 2.84505 6.21094 3.53516C5.07812 4.1862 4.1862 5.07812 3.53516 6.21094C2.84505 7.38281 2.5 8.64583 2.5 10C2.5 11.3542 2.84505 12.6172 3.53516 13.7891C4.1862 14.9219 5.07812 15.8203 6.21094 16.4844C7.38281 17.1615 8.64583 17.5 10 17.5C11.3542 17.5 12.6172 17.1615 13.7891 16.4844C14.9219 15.8203 15.8203 14.9219 16.4844 13.7891C17.1615 12.6172 17.5 11.3542 17.5 10C17.5 8.64583 17.1615 7.38281 16.4844 6.21094C15.8203 5.07812 14.9219 4.1862 13.7891 3.53516C12.6172 2.84505 11.3542 2.5 10 2.5ZM10 3.75C11.1328 3.75 12.1875 4.03646 13.1641 4.60938C14.1016 5.15625 14.8438 5.89844 15.3906 6.83594C15.9635 7.8125 16.25 8.86719 16.25 10C16.25 11.1328 15.9635 12.1875 15.3906 13.1641C14.8438 14.1016 14.1016 14.8438 13.1641 15.3906C12.1875 15.9635 11.1328 16.25 10 16.25C8.86719 16.25 7.8125 15.9635 6.83594 15.3906C5.89844 14.8438 5.15625 14.1016 4.60938 13.1641C4.03646 12.1875 3.75 11.1328 3.75 10C3.75 8.86719 4.03646 7.8125 4.60938 6.83594C5.15625 5.89844 5.89844 5.15625 6.83594 4.60938C7.8125 4.03646 8.86719 3.75 10 3.75ZM9.375 6.25V11.25H10.625V6.25H9.375ZM9.375 12.5V13.75H10.625V12.5H9.375Z' fill='%23E73C5F'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

.ticketTotals {
  margin-top: 80px;
  border-radius: 12px;
  background: #ffffff;
  --paddingLR: 2.5vw;
  color: #000000;
  margin-bottom: 150px;
  padding: 48px var(--paddingLR);

  &__agree {
    margin-top: 32px;
  }

  &__row {
    color: #7F7F94;
    padding: 8px 0;
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #DFE2E3;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;

      &:last-child {
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 34px;
      }
    }
  }

  &__btnConfirm {
    border-radius: 12px;
    transition: 0.3s ease all;
    margin-top: 35px;
    padding: 20px;
    background: #E73C5F;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    &:hover {
      background: #f8466a;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__cards {
    display: flex;
  }

  &__title {
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    font-size: 26px;
    margin-bottom: 48px;
  }

  &__card {
    &__title {
      font-family: 'TT Norms';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
    }

    &:nth-child(1) {
      width: 100%;
      max-width: 420px;
      margin-right: var(--paddingLR);
    }

    &:nth-child(2) {
      width: 100%;
      max-width: 444px;
      margin-right: var(--paddingLR);
    }

    &:nth-child(3) {
      width: 100%;
      border-left: 1px solid #DFE2E3;
      padding-left: calc(var(--paddingLR) / 2);
    }
  }
}

.guests {
  &:deep(.card__body) {
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    grid-template-areas:
        'f f'
        's s'
        't t'
        'fr fr';

    .guestRow {
      &:nth-child(1) {
        grid-area: f;
      }

      &:nth-child(2) {
        grid-area: s;
      }

      &:nth-child(3) {
        grid-area: t;
      }

      &:nth-child(4) {
        grid-area: fr;
      }
    }

  }


}


.wrapButtons {
  --gap: 3.6%;

  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;

  &--fullWidth {
    display: grid;
    grid-template-columns: repeat(4, calc((100% - (var(--gap) * 3)) / 4));
    grid-column-gap: var(--gap);
  }
}

hr {
  border: none;
  border-bottom: 1px solid #393F67;
  margin-top: 16px;
  margin-bottom: 16px;
}

.text {
  margin-top: 16px;
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  max-width: 376px;

  &__title {
    font-size: 20px;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 6px;
  }

  &__list {
    list-style: disc;
    padding-left: 16px;
  }
}

@media screen and (max-width: 1700px) {

  .wrapButtons {

    &--fullWidth {
      display: grid;
      grid-template-columns: repeat(2, calc((100% - (var(--gap))) / 2));
      grid-column-gap: var(--gap);
    }

    &:deep(.selectButton) {
      width: 100%;
      margin-bottom: 12px;
    }
  }
}

@media screen and (max-width: 1246px) {
  .ticketTotals {
    &__cards {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__card {

      &:nth-child(1) {
        max-width: calc(50% - (var(--paddingLR) / 2));
        margin-right: 0px
      }

      &:nth-child(2) {
        max-width: calc(50% - (var(--paddingLR) / 2));
        margin-right: 0px
      }

      &:nth-child(3) {
        border-left: none;
        padding-left: 0;
        width: 100%;
        border-top: 1px solid #DFE2E3;
        padding-top: 24px;
      }


    }
  }
  .ticketController {
    grid-template-areas:
            'f f f s s s'
            't t t t t t';
  }
  .guests {
    &:deep(.card__body) {
      display: grid;
      grid-template-columns:repeat(2, 1fr);
      grid-template-areas:
        'f s'
        't fr';
      grid-gap: 1.25vw;
    }
  }


}

@media screen and (max-width: 768px) {
  .ticketController {
    margin-top: 24px;
    grid-template-areas:
            'f f f f f f'
            's s s s s s'
            't t t t t t';
    grid-row-gap: 32px;

    &__info {
      margin-top: 20px;

      li {
        margin-bottom: 2px;
      }
    }
  }
  .text {
    &__title {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .guests {
    &:deep(.card__body) {
      grid-template-areas:
        'f f'
        's s'
        't t'
        'fr fr';
    }
  }

  .ticketTotals {
    margin-top: 32px;
    padding: 20px;
    padding-bottom: 44px;
    margin-bottom: 80px;

    &__card {
      margin-bottom: -24px;
    }

    &__title {
      margin-bottom: 0px;
      font-size: 22px;
      line-height: 30px;
    }

    &__btnConfirm {
      margin-top: 24px;
    }

    &__total {
      margin-top: 25px;

      span {
        &:last-child {
          font-size: 22px;
          line-height: 30px;
        }
      }
    }

    &__card {
      &__title {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 4px;
      }

      &:nth-child(1) {
        max-width: 100%;
        width: 100%;
      }

      &:nth-child(2) {
        max-width: 100%;
        width: 100%;
      }

      &:nth-child(3) {
        width: 100%;
      }


    }
  }
  .timer {
    font-size: 18px;
    line-height: 26px;
    margin-top: 24px;
    text-align: center;

    span {
      display: block;
      color: #E73C5F;
      text-align: center;
    }
  }

}
