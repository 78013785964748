article {
  p, li {
    color: $text-color;
  }

  h3 {
    font-weight: bold;
  }

  h3 {
    color: #000000;
    span {
      strong {
        font-weight: bold;
      }
    }
  }

  strong {
    color: #000000;
    font-weight: 500;
  }

  ul {
    li {
      position: relative;

      &:before {
        position: absolute;
        background-color: $secondary-color;
        content: "";
        left: 0;
        top: 25px;
        width: 25px;
        height: 3px;
        display: block;
      }
    }
  }

  ol {
    counter-reset: heading;

    li {
      display: flex;

      &:before {
        color: $secondary-color;
        font-weight: 600;
        font-size: 15px;
        line-height: 155%;
        counter-increment: heading;
        content: "0" counter(heading) ". ";
      }
    }
  }

}

@include respond-up('large') {
  article {

    p {
      font-size: 17px;
      line-height: 30px;
      span {
        font-size: 17px;
        line-height: 30px;
      }
    }

    ul {
      li {
        padding: 10px 0 10px 60px;
        font-size: 17px;
        line-height: 30px;
      }
    }

    ol {
      li {
        font-size: 17px;
        line-height: 30px;
        span {
          font-size: 17px;
          line-height: 30px;
        }
        padding: 15px 0;

        &:before {
          margin-right: 34px;
          margin-top: 4px;
        }
      }
    }

    strong {
      font-size: 23px;
      line-height: 35px;
    }

    h3 {

    }

    h3 {
      span {
        strong {
          font-size: 35px;
          line-height: 52px;
        }
      }
    }
  }
}

@include respond('medium') {
  article {

    p {
      font-size: 15px;
      line-height: 27px;
      span {
        font-size: 15px;
        line-height: 27px;
      }
    }

    ul {
      li {

        &:before {
          position: absolute;
          background-color: $secondary-color;
          content: "";
          left: 0;
          top: 19px;
          width: 25px;
          height: 3px;
          display: block;
        }
        span {
          font-size: 15px;
          line-height: 27px;
        }
        padding: 10px 0 10px 48px;
      }
    }

    ol {
      li {
        font-size: 15px;
        line-height: 27px;
        span {
          font-size: 15px;
          line-height: 27px;
        }
        padding: 14px 0;

        &:before {
          margin-right: 24px;
          margin-top: 2px;
        }
      }
    }

    p {
      strong {
        span {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }

    h3 {
      span {
        strong {
          font-size: 28px;
          line-height: 38px;
        }
      }
    }
  }
}

@include respond-down('small') {
  article {
    p {
      font-size: 15px;
      line-height: 27px;
      span {
        font-size: 15px;
        line-height: 27px;
      }
    }

    ul {
      li {
        span {
          font-size: 15px;
          line-height: 27px;
        }
        padding: 10px 0 10px 40px;
        font-size: 14px;
        line-height: 27px;

        &:before {
          top: 22px;
        }
      }
    }

    ol {
      li {
        font-size: 15px;
        line-height: 27px;
        padding: 14px 0;
        &:before {
          margin-right: 13px;
          margin-top: 2px;
        }
      }
    }

    p {
      strong {
        span {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }

    h3 {
      span {
        strong {
          font-size: 25px;
          line-height: 35px;
        }
      }
    }
  }
}