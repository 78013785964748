
.agreeController {
  cursor: pointer;
  padding-left: 26px;
  position: relative;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  display: block;
  color: #7F7F94;



  >>>a {
    color: #E73C5F;
  }

  .svg {
    position: absolute;
    left: 0;
    top: 4.5px;

    &__check {
      transition: 0.3s ease all;
      opacity: 0;
    }
  }

  &--checked {
    .svg {
      &__check {
        opacity: 1;
      }
    }
  }
}

