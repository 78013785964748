
.modalWrap {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &__bg {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7  );
    z-index: 9998;
  }
}

.modalWindow {
  &__body {
    padding: 60px;
    background: #262D5B;
    width: 100%;
    max-width: 465px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 9999;
    overflow: hidden;
    border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;

    &:deep(a) {
      text-decoration: underline;
        color:#E73C5F

    }

    &__title {
      font-family: 'TT Norms';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 24px;
        text-align: center;
    }
  }

}

.modalClose {
  top: 20px;
  right: 20px;
  position: absolute;
  background: transparent;
}

@media screen and (max-width: 767px) {
  .modalWindow {
    &__body {
      padding: 20px;
        width: calc(100% - 40px);
        max-width: 280px;
        &__title {
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
        }
    }

  }
    .modalClose {
        top: 12px;
        right: 13px;
        position: absolute;
        background: transparent;
    }
}
