.floating-field {
  position: relative;

  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    margin: 0;
    pointer-events: none;
    transition: all 0.2s;
  }

  &._filled label,
  input:focus + label,
  textarea:focus + label {
    width: 100%;
    color: #B4B4B4;
    transform: translateY(0);
  }
}

@include respond-up('large') {
  .floating-field {
    label {
      height: 56px;
      font-size: 20px;
    }

    &._filled label,
    input:focus + label,
    textarea:focus + label {
      height: 24px;
      font-size: 14px;
      transform: translateY(-10px);
    }
  }
}

@include respond-down('medium') {
  .floating-field {
    label {
      height: 45px;
      font-size: 17px;
    }

    &._filled label,
    input:focus + label,
    textarea:focus + label {
      height: 20px;
      font-size: 12px;
      transform: translateY(-8px);
    }
  }
}