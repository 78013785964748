$cubic-bezier: cubic-bezier(.25, .1, .25, .9);

.hero {
  &__wrap {
    position: relative;
    min-height: 100vh;
    overflow: hidden;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: {
        image: url('/static/images/back.png');
        repeat: no-repeat;
        size: cover;
        position: center;
      };
    }

    &._init {
      .hero {
        &__slider,
        &__buttons,
        &__nav-container {
          opacity: 1;
          transform: translate(0);
        }

        &__images {
          transform: scale(1);
          transition: transform 1.7s cubic-bezier(.74, .11, .31, .88);


        }

        &__names {

        }
      }
    }
  }

  &__main {
    position: relative;
    z-index: 11;
  }

  &__slider {
    transition: transform 1.7s cubic-bezier(.5, .12, .25, .90);
  }

  &__names {
    transition-property: transform;
    transition-timing-function: cubic-bezier(.5, .12, .25, .9);
  }

  &__name-wrap {
    opacity: 1;
    transition: opacity .4s $cubic-bezier;

    &._hide {
      opacity: 0;
      transition: none;
    }

    &._active {
      .hero__name {
        color: #fff;
        transition: color .8s $cubic-bezier .25s;
      }
    }
  }

  &__name {
    color: $t;
    font-weight: bold;
    white-space: nowrap;
    text-transform: uppercase;
    transition: color .8s $cubic-bezier;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
  }

  &__nav-container {
    opacity: 0;
    transform: translateX(-40px);
    transition: all .6s $cubic-bezier .8s;
  }

  &__nav {
    display: flex;
  }

  &__arrows {
    display: flex;
    flex-direction: column;
  }

  &__arrow {

  }

  &__descriptions {
    position: relative;
    margin-top: 5px;
  }

  &__description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 300px;
    font-weight: 500;
    opacity: 0;
    transform: translateX(20px);
    transition: all .5s $cubic-bezier .1s;
    pointer-events: none;

    &._active {
      transform: translateX(-40px);
      pointer-events: auto;
      transition: none;
      z-index: 100;

      &._anime {
        opacity: 1;
        transform: translateX(0);
        transition: all 1s $cubic-bezier .4s;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    opacity: 0;
    transform: translateY(20px);
    transition: all .7s $cubic-bezier 1.2s;
  }

  &__button {
    display: none;

    &._active {
      display: block;
    }
  }

  &__images {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: scale(1.1);
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: opacity .8s $cubic-bezier;

    &._active {
      transform: scale(1.1);
      transition: none;

      &._anime {
        opacity: 1;
        transform: scale(1);
        transition: all 1.7s cubic-bezier(.74, .11, .31, .88);
      }
    }
  }
}

@include respond-up('large') {
  .hero {
    &__wrap {
      padding: 200/777*100vh 0 50/777*100vh;
    }

    &__slider {
      transform: translateX(calc(100vw - #{$indent-large}));
    }

    &__name {
      transform: translateX($indent-large);
      font-size: 120/1500*100vw;
      line-height: 140/120*1em;
    }

    &__nav {
      margin-top: 68/777*100vh;
    }

    &__arrows {
      margin: -8px;
    }

    &__arrow {
      padding: 8px;

      &:hover {
        .svg-arrow {
          &__main {
            fill: #fff;
          }
        }
      }

      .svg-arrow {
        &__main {
          fill: $t;
          transition: $default-transition;
        }
      }
    }

    &__descriptions {
      margin-left: 74px;
      min-width: 300px;
    }

    &__description {
      font-size: 16px;
      line-height: 26/16*1em;
    }

    &__buttons {
      margin-top: 18/777*100vh;
    }
  }
}

@include respond('medium') {
  .hero {
    &__wrap {
      padding: 110/498*100vh 0 50/498*100vh;
    }

    &__slider {
      transform: translateX(calc(100vw - #{$indent-medium}));
    }

    &__name {
      transform: translateX($indent-medium);
      font-size: 70/768*100vw;
      line-height: 82/70*1em;
    }

    &__nav {
      margin-top: 30/498*100vh;
    }

    &__buttons {
      margin-top: 48/498*100vh;
    }

    &__descriptions {
      min-width: 300px;
    }
  }
}

@include respond-down('medium') {
  .hero {
    &__arrows {
      margin: -7px;
    }

    &__arrow {
      padding: 7px;

      svg {
        height: 38px;
        width: 44px;
      }
    }

    &__descriptions {
      margin-left: 34px;
    }

    &__description {
      font-size: 15px;
      line-height: 25/15*1em;
    }
  }
}

@include respond-down('small') {
  .hero {
    &__wrap {
      padding: 120/594*100vh 0 70/594*100vh;
    }

    &__slider {
      transform: translateX(calc(100vw - #{$indent-small}));
    }

    &__name {
      transform: translateX($indent-small);
      font-size: 45/375*100vw;
      line-height: 52/45*1em;
    }

    &__nav {
      margin-top: 40/594*100vh;
    }

    &__buttons {
      margin-top: 84/594*100vh;
    }

    &__descriptions {
      margin-left: 34/375*100vw;
      width: 265/375*100vw;
    }
  }
}