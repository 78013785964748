.floor {
  transition: {
    property: background-color;
    duration: .5s;
  };

  &__info {
    position: relative;
    height: 100%;
  }

  &__title {
    font-weight: bold;
    text-transform: uppercase;
  }

  &__number {
    display: block;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    color: transparent;
    font-weight: bold;
    transform: translateX(56%);
    transition: {
      timing-function: ease-out;
      duration: 1.98s;
      property: transform;
    };
  }

  &__number-wrapper{
    position: absolute;
    text-align: center;
  }

  &__description {
    color: $text-color;
    background-color: white;
  }

  &__body {
    overflow: hidden;
  }

  &__inner {
    position: relative;
    display: flex;
  }

  &__slider {
    flex: 0 0 100%;
    z-index: 100;
    transform: translateX(5.3%);
    transition: {
      timing-function: cubic-bezier(0, 0, 0.58, 1);
      duration: 1.98s;
      property: transform;
    };
  }

  &__container {
    overflow: hidden;
  }

  &__item{
    &.animate{
      .floor{
        &__number{
          transform: translateX(0);
        }
        &__slider {
          transform: translateX(0);
        }
      }
    }
  }
}

@include respond-up('large') {
  .floor {

    &__item{
      &:nth-child(1) {
        .floor__info {
          padding: 182/1500*100vw 0 94/1500*100vw 150/1500*100vw;

        }
        .floor__number-wrapper {
          top: 122/1500*100vw;
        }
        .floor__description {
          padding: 160/1500*100vw 179/1500*100vw 73/1500*100vw 10vw;
        }
      }
    }

    &__color-container{
      flex: 0 0 682/1500*100vw;
    }

    &__info {
      width: 100%;
      padding: 300/1500*100vw 0 103/1500*100vw 150/1500*100vw;
      display: flex;
      align-items: flex-start;
    }

    &__body {
      display: flex;

    }

    &__title {
      font-size: 50/1500*100vw;
      line-height: 60/50*1em;
      width: 359/1500*100vw;
      min-height: 118/1500*100vw;
    }

    &__number-wrapper{
      top: 249/1500*100vw;
      width: 195/1500*100vw;
      right: -74/1500*100vw;
    }

    &__number {
      font-size: 300/1500*100vw;
      line-height: 220/300*1em;
    }

    &__description {
      flex: 1 1 auto;
      padding: 290/1500*100vw 179/1500*100vw 73/1500*100vw 10vw;
      font-size: 17/1500*100vw;
      line-height: 30/17*1em;
    }

    &__inner {
      width: 100%;
    }

    &__slider {
      margin-bottom: -129/1500*100vw;
    }
    &__container {
      padding-bottom: 125px;
    }
  }
}

@include respond('medium') {
  .floor {
    &__item{
      &:nth-child(1) {
        .floor{
          &__info {
            padding: 75/768*100vw 0 91/768*100vw $indent-medium;
          }
          &__number-wrapper {
            top: 50/768*100vw;
          }

          &__description {
            padding: 75/768*100vw $indent-medium 50/768*100vw 63/768*100vw;
          }
        }
      }
    }

    &__color-container{
      flex: 0 0 354/768*100vw;
    }

    &__info {
      padding: 150/768*100vw 0 156/768*100vw $indent-medium;
      display: flex;
    }

    &__body {
      display: flex;

    }

    &__title {
      font-size: 35/768*100vw;
      line-height: 45/35*1em;
      width: 231/768*100vw;
    }

    &__number-wrapper{
      top: 130/768*100vw;
      width: 130/768*100vw;
      right: -44/768*100vw;
    }

    &__number {
      font-size: 200/768*100vw;
      line-height: 160/200*1em;
    }

    &__description {
      width: 100%;
      padding: 150/768*100vw $indent-medium 50/768*100vw 63/768*100vw;
      font-size: 15/768*100vw;
      line-height: 28/15*1em;
    }

    &__inner {
      width: 100vw;
    }

    &__slider {
      margin-bottom: -80/768*100vw;
    }

    &__container {
      padding-bottom: 56px;
    }
  }
}

@include respond-down('small') {
  .floor {
    &__item{
      &:nth-child(1) {
        .floor{
          &__info {
            padding: 47/375*100vw 0 15/375*100vw $indent-small;
          }
          &__number-wrapper {
            top: 44/375*100vw;
          }
        }
      }
    }

    &__color-container{
      flex: 0 0 100%;
    }

    &__info {
      padding: 100/375*100vw 0 15/375*100vw $indent-small;
      display: flex;
      position: relative;
    }

    &__title {
      max-width: 231/375*100vw;
      font-size: 30/375*100vw;
      line-height: 40/30*1em;
      width: 199/375*100vw;
    }

    &__number-wrapper{
      top: 106/375*100vw;
      width: 123/375*100vw;
      right: -29/375*100vw;
    }

    &__number {
      font-size: 200/375*100vw;
      line-height: 160/200*1em;
    }

    &__description {
      padding: 0 0 35/375*100vw $indent-small;
      display: block;
      background-color: transparent;
      color: white;
      font-size: 15/375*100vw;
      line-height: 26/15*1em;
      max-width: 252/375*100vw;
      min-height: 140/375*100vw;
    }

    &__slider {
      margin-bottom: -60/375*100vw;
    }

    &__container {
      padding-bottom: 80px;
    }
  }
}



