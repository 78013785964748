$modal-bg: $background-color !default;

$modal-content-radius: 0 !default;
$modal-content-shadow: none !default;
$modal-content-padding: none !default;
$modal-content-background: $t !default;

$modal-closer-color: $modal-bg !default;
$modal-closer-size: 30px !default;

$modal-preloader-size: 30px;

.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  background: $modal-bg;
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &.opened {
    display: block;
  }

  &.popup {
    background-color:rgba(0, 0, 0, 0.7);
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &.opened {
    display: flex;
  }
}

.modal__container {
  padding: $modal-content-padding;
  border-radius: $modal-content-radius;
  background: $modal-content-background;
  box-shadow: $modal-content-shadow;
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  position: absolute;
  top: 26px;
  right: 96px;
  width: 45px;
  height: 45px;

  font-size: $modal-closer-size;
  color: $modal-closer-color;

  text-decoration: none;
  cursor: pointer;

  &:hover {
    &::before,
    &::after {
      background-color: #fff;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 2px;
    width: 28px;
    background: #727589;
    transition: $default-transition;
  }

  &::before {
    transform: translate(-50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%) rotate(45deg);
  }
}

.modal__loader {
  display: block;
  position: fixed;
  z-index: 3000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: $modal-bg;
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

body.modal-loading {
  overflow: hidden;
  $preloaderSize: 100px;

  .modal__loader {
    opacity: 1;
    visibility: visible;

    &:before, &:after {
      content: '';
      position: fixed;
      width: $preloaderSize;
      height: $preloaderSize;
      left: 50%;
      top: 50%;
      margin-left: -$preloaderSize/2;
      margin-top: -$preloaderSize/2;
      background-color: #000;
      border-radius: $preloaderSize/2;
      transform: scale(0);
    }

    &:before {
      background-color: lighten($primary-color, 15%);
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
      animation-delay: 0.4s;
    }

    &:after {
      background-color: $primary-color;
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
    }
  }
}

@include respond-up('large') {
  .modal {
    &__container {
      &.popup {
        position: relative;

        .modal {
          &__content {
            position: relative;
            z-index: 10;
          }

          &__closer {
            right: 80px;
            z-index: 100;
            border-radius: 100%;
            border: 1px solid #727589;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .modal {
    &__content {
      padding: 28px 38px;
    }

    &__closer {
      top: 16px;
      right: 28px;
    }

    &__container {
      &.popup {
        .modal {
          &__closer {
            top: 36px;
            right: 47px;
            z-index: 10;

            &::before, &::after {
              background: #FFFFFF;
            }
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .modal {
    &__layout {
      align-items: flex-start;
    }

    &__container {
      flex: 0 0 100%;

      &.popup {
        .modal {
          &__content {
            padding: 0;
          }

          &__closer {
            z-index: 10;

            &::before, &::after {
              background: #FFFFFF;
            }
          }
        }
      }
    }

    &__content {
      padding: 95px 18px 64px;
    }

    &__closer {
      top: 6px;
      right: 8px;
    }
  }
}

@include respond-down('x-small') {
  .modal {
    &__content {
      padding: 95px 16px 64px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}