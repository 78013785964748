.main-menu {
  position: fixed;
  z-index: 400;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 0;
  overflow: hidden;

  &__label {
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 2px;
    line-height: 12/11*1em;
    margin-bottom: 16px;
    color: rgba(255, 255, 255, 0.60);
  }

  &__app-link-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__app-link {
    display: flex;

    &:first-child {
      margin-right: 8px;
    }
  }

  &._open {
    height: 100vh;

    .main-menu {
      &__inner {
        opacity: 1;
      }

      &__item {
        opacity: 1;
        transform: translateX(0);
      }

      &__info {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__inner {
    opacity: 0;
    transition: {
      property: opacity;
      duration: .3s;
    };
    height: 100%;
    width: 100%;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
  }

  &__list {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }

  &__item {
    opacity: 0;
    transition: {
      property: transform, opacity;
      duration: .96s;
      timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      delay: .28s;
    };
  }

  &__link {
    font-size: 70/1500*100vw;
    color: $t;
    line-height: 110/70*1em;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 75/1500*100vw;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    transition: {
      property: color;
      timing-function: ease-in-out;
      duration: .5s;
    };

    &:hover {
      color: white;
    }
  }

  &__info {
    display: grid;
    transform: translateY(30px);
    opacity: 0;
    transition: {
      property: opacity, transform;
      delay: .65s;
      duration: .65s;
      timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    };
  }

  &__lk {
    transition: $default-transition;
    display: flex;
    align-items: center;
    padding: 19px 25px 20px 25px;
    background-color: rgba(67, 82, 180, 0.15);
    grid-area: lk;
    width: fit-content;

    &:hover {
      background-color: #2B357B;
    }
  }

  &__lk-text {
    margin-left: 16px;
    font-weight: bold;
    font-size: 13px;
    color: white;
    line-height: 15/13*1em;
    text-transform: uppercase;
  }

  &__address {
    color: white;
  }

  &__email {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.60);
    line-height: 16/15*1em;
  }

  &__app-link-block {
    grid-area: app;
  }

  &__socials {
    grid-area: socials;
    display: flex;
  }

  &__contacts {
    grid-area: contacts;
  }
}

@include respond-up('large') {
  .main-menu {
    &__info {
      grid-template-areas: "lk app socials contacts";
    }

    &__inner {
      padding: 31/777*100vh 124/1500*100vw 95/777*100vh 194/1500*100vw;
    }

    &__list {
      margin: 0 -75/1500*100vw;
    }

    &__link {
      font-size: 70/1500*100vw;
      line-height: 110/70*1em;
      padding: 0 75/1500*100vw;
    }

    &__address {
      font-size: 20px;
      line-height: 23/20*1em;
      margin-bottom: 12px;
    }

    &__socials,
    &__contacts {
      padding-top: 17px;
    }

    &__item {
      &:nth-child(-n+3) {
        transform: translateX(50px);
      }

      &:nth-child(n+4) {
        transform: translateX(-50px);
      }
    }
  }
}

@include respond('medium') {
  .main-menu {
    &__inner {
      padding: 114/662*100vh 82/768*100vw 39/662*100vh 37/1500*100vw;
    }

    &__list {
      margin: 0 -45/768*100vw;
    }

    &__link {
      font-size: 50/768*100vw;
      line-height: 80/50*1em;
      padding: 0 45/768*100vw;
    }

    &__info {
      grid-row-gap: 50/662*100vh;
      grid-template-areas:
        "lk contacts"
        "app socials";
    }

    &__item {
      &:nth-child(-n+2), &:nth-child(6) {
        transform: translateX(30px);
      }

      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        transform: translateX(-30px);
      }
    }
  }
}

@include respond-down('medium') {
  .main-menu {
    &__address {
      font-size: 18px;
      line-height: 23/18*1em;
      margin-bottom: 11px;
    }
  }
}

@include respond-down('small') {
  .main-menu {
    &__info {
      grid-row-gap: 30/617*100vh;
      grid-template-areas:
        "lk"
        "app"
        "contacts"
        "socials";
    }

    &__inner {
      padding: 87/617*100vh 17/375*100vw 59/617*100vh;
    }

    &__list {
      margin: 0 -26/375*100vw;
    }

    &__link {
      font-size: 30/375*100vw;
      line-height: 55/30*1em;
      padding: 0 26/375*100vw;
    }

    &__item {
      &:nth-child(1), &:nth-child(2), &:nth-child(5), &:nth-child(6) {
        transform: translateX(20px);
      }

      &:nth-child(3), &:nth-child(4) {
        transform: translateX(-20px);
      }
    }

    &__app-link-wrapper {
      @media screen and (max-width: 350px) {
        flex-direction: column;
      }
    }

    &__app-link {
      @media screen and (max-width: 350px) {
        &:first-child {
          margin-right: 0;
          margin-bottom: 8px;
        }
      }
    }
  }
}
