.double-slider{

  &__container{
    position: relative;
  }

  &__list{
    position: relative;
    z-index: 100;
    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }

  &__item{

  }

  &__image{
    height: 648/1500*100vw;
    background: {
      position: center;
      size: cover;
      repeat: no-repeat;
    };

    &._big{
      transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
  }

  &__alt {
   display: none;
  }
}

@include respond-up('large'){
  .double-slider{
    &__item{
      &._small{
        padding-left: 150/1500*100vw;
      }

      &.tns-slide-active{
        .double-slider{
          &__image._big{
            transform: translateX(150/1500*100vw);
          }
        }
      }
    }

    &__image{
      height: 648/1500*100vw;

      &._big{
        transition: {
          duration: 1.4s;
          timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        };
      }
    }

    &__arrow-block{
      position: absolute;
      right: 85/1500*100vw;
      top: calc(50% - 40px);
      z-index: 200;
    }

    &__arrow {
      display: block;
      transition: {
        property: transform;
        duration: .5s;
      };

      &:hover {
        .svg-arrow {
          &__main {
            fill: #fff;
          }
        }
      }

      .svg-arrow {
        height: 79px;
        width: 92px;

        &__main {
          fill: $t;
          transition: $default-transition;
        }
      }

      &._invert{
        transform: rotate(-180deg);
      }
    }
  }
}

@include respond-up('medium'){
  .double-slider{

  }
}

@include respond('medium'){
  .double-slider{
    &__item{
      &._small{
        padding-left: 38/768*100vw;
      }

      &.tns-slide-active{
        .double-slider{
          &__image._big{
            transform: translateX(38/768*100vw);
          }
        }
      }
    }

    &__image{
      height: 352/768*100vw;

      &._big{
        transition-duration: .75s;
      }
    }
  }
}

@include respond-down('medium'){
  .double-slider{
    &__arrow-block{
      display: none;
    }

    &__image{
      height: 352/768*100vw;
    }
  }
}

@include respond-down('small'){
  .double-slider{
    &__item{
      &:first-child{
        padding-left: 18/375*100vw;
      }
    }

    &__image{
      height: 360/375*100vw;
    }
  }
}