@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-Thin.eot');
    src: local('TTNorms Thin'), local('TTNorms-Thin'),
    url('../fonts/TTNorms-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-Thin.woff') format('woff'),
    url('../fonts/TTNorms-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-ThinItalic.eot');
    src: local('TTNorms ThinItalic'), local('TTNorms-ThinItalic'),
    url('../fonts/TTNorms-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-ThinItalic.woff') format('woff'),
    url('../fonts/TTNorms-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-ExtraLight.eot');
    src: local('TTNorms ExtraLight'), local('TTNorms-ExtraLight'),
    url('../fonts/TTNorms-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-ExtraLight.woff') format('woff'),
    url('../fonts/TTNorms-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-ExtraLightItalic.eot');
    src: local('TTNorms ExtraLightItalic'), local('TTNorms-ExtraLightItalic'),
    url('../fonts/TTNorms-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-ExtraLightItalic.woff') format('woff'),
    url('../fonts/TTNorms-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-Light.eot');
    src: local('TTNorms Light'), local('TTNorms-Light'),
    url('../fonts/TTNorms-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-Light.woff') format('woff'),
    url('../fonts/TTNorms-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-LightItalic.eot');
    src: local('TTNorms LightItalic'), local('TTNorms-LightItalic'),
    url('../fonts/TTNorms-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-LightItalic.woff') format('woff'),
    url('../fonts/TTNorms-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-Medium.eot');
    src: local('TTNorms Medium'), local('TTNorms-Medium'),
    url('../fonts/TTNorms-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-Medium.woff') format('woff'),
    url('../fonts/TTNorms-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-MediumItalic.eot');
    src: local('TTNorms MediumItalic'), local('TTNorms-MediumItalic'),
    url('../fonts/TTNorms-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-MediumItalic.woff') format('woff'),
    url('../fonts/TTNorms-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-Regular.eot');
    src: local('TTNorms Regular'), local('TTNorms-Regular'),
    url('../fonts/TTNorms-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-Regular.woff') format('woff'),
    url('../fonts/TTNorms-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-Bold.eot');
    src: local('TTNorms Bold'), local('TTNorms-Bold'),
    url('../fonts/TTNorms-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-Bold.woff') format('woff'),
    url('../fonts/TTNorms-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-BoldItalic.eot');
    src: local('TTNorms BoldItalic'), local('TTNorms-BoldItalic'),
    url('../fonts/TTNorms-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-BoldItalic.woff') format('woff'),
    url('../fonts/TTNorms-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-ExtraBold.eot');
    src: local('TTNorms ExtraBold'), local('TTNorms-ExtraBold'),
    url('../fonts/TTNorms-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-ExtraBold.woff') format('woff'),
    url('../fonts/TTNorms-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-ExtraBoldItalic.eot');
    src: local('TTNorms ExtraBoldItalic'), local('TTNorms-ExtraBoldItalic'),
    url('../fonts/TTNorms-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-ExtraBoldItalic.woff') format('woff'),
    url('../fonts/TTNorms-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-Heavy.eot');
    src: local('TTNorms Heavy'), local('TTNorms-Heavy'),
    url('../fonts/TTNorms-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-Heavy.woff') format('woff'),
    url('../fonts/TTNorms-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms';
    src: url('../fonts/TTNorms-HeavyItalic.eot');
    src: local('TTNorms HeavyItalic'), local('TTNorms-HeavyItalic'),
    url('../fonts/TTNorms-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TTNorms-HeavyItalic.woff') format('woff'),
    url('../fonts/TTNorms-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}



