.about-head {
  &__text {
     color: $text-color-transparent;
     font-size: 21px;
     line-height: 32px;
    transform: translateX(60px);
    opacity: 0;
    transition: {
      property: opacity, transform;
      delay: 1.42s;
      duration: 1.5s;
    };
  }

  &.animate{
    .about-head__text{
      transform: translateX(0);
      opacity: 1;
    }
  }
}


@include respond-up('large') {
  .about-head {
    padding-bottom: 110px;
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: 100px;
      flex: 0 0 500px;
    }

    &__text {
      flex: 0 0 42%;
    }
  }
}

@include respond-down('medium') {
  .about-head {
    padding-bottom: 60px;

    &__title {
      font-size: 60px;

      margin-bottom: 30px;
    }

    &__text {
      padding-top: 30px;
      padding-bottom: 60px;
      font-size: 18px;
    }
  }
}

@include respond-down('small') {
  .about-head {
    padding-bottom: 50px;

    &__title {
      font-size: 35px;
      margin-bottom: 20px;
    }

    &__text {
      font-size: 16px;
    }
  }
}