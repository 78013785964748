.plate-link {
  background-color: #EBF0F5;
  display: block;
  max-width: 417px;
  transition: $default-transition;

  &:hover {
    .plate-link {
      &__footer {
        background-color: $primary-color;
      }

      &__click {
        color: #fff;
      }

      &__icon {
        .svg-plus {
          &__plus {
            fill: #fff;
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 36px 68px 22px 36px;
  }

  &__text {
    font-size: 20px;
    color: #000000;
    line-height: 30/20*1em;
  }

  &__click {
    line-height: 15/13*1em;
    font-size: 13px;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
    transition: $default-transition;
  }

  &__footer {
    display: flex;
    padding: 27px 0 22px;
    justify-content: center;
    border-top: 1px dashed rgba(0, 156, 224, .55);
    transition: $default-transition;
  }

  &__label {
    padding: 6px 12px;
    background-color: $primary-color;
    font-size: 11px;
    line-height: 13/11*1em;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 18px;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 15px;

    .svg-plus {
      &__plus {
        transition: $default-transition;
      }
    }
  }
}

@include respond('medium') {
  .plate-link {
    max-width: 100%;
    display: flex;

    &__wrapper {
      padding: 25px 95px 25px 40px;
      flex: 0 0 70%;
    }

    &__footer {
      padding: 0;
      width: 100%;
      align-items: center;
      border-top: none;
      border-left: 1px dashed rgba(0, 156, 224, .55);
    }

    &__icon {
      height: 15px;
    }
  }
}

@include respond-down('small') {
  .plate-link {
    max-width: 100%;

    &__wrapper {
      padding: 30px 23px;
    }

    &__footer {
      padding: 30px 0 25px;
    }
  }
}