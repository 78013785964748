.recall-modal {
  &__subtitle {
    color: $text-color-transparent;
  }

  &__info {
    position: relative;

    &_popup {
      display: flex;
      flex-direction: column;

      .recall-modal {
        &__title, &__subtitle {
          position: relative;
          z-index: 5;
        }

        &__shadow-background {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: #0C1026;
          opacity: 0.44;
        }

        &__image {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &__label {
          position: relative;
          z-index: 5;
          font-weight: 500;
          font-size: 11px;
          line-height: 15/11*1em;
          letter-spacing: 0.015em;
          color: #FFFFFF;
          padding: 8px 12px;
          width: fit-content;
        }

        &__title {
          font-weight: bold;
          color: #FFFFFF;
        }

        &__subtitle {
          margin-top: auto;
          font-weight: normal;
          font-size: 13px;
          line-height: 18/13*1em;
          color: #FFFFFF;
          opacity: 0.7;
          width: 273px;
        }
      }
    }
  }
}

@include respond-up('large') {
  .recall-modal {
    &__wrap {
      position: relative;

      &_popup {
        overflow: hidden;
        display: grid;
        grid-template-columns: 522px calc(481/1500*100vw);

        .recall-modal {
          &__form {
            min-width: 620px;
            flex: 0 0 481/1500*100vw;
          }
        }
      }
    }

    &__info {
      flex: 0 0 411px;
      padding: 69px 126px 69px 0;

      &_popup {
        flex: 0 0 522px;
        height: 490px;
        padding: 47px 62px 30px 50px;

        &:before {
          position: absolute;
          content: '';
          height: 80px;
          right: 0;
          left: 0;
          bottom: -80px;
          background: #FFFFFF;
        }

        .recall-modal {
          &__label {
            margin-bottom: 32px;
          }
        }
      }
    }

    &__form {
      flex: 0 0 623/1500*100vw;

      &_success {
        padding: 100/1500*100vw 0;
      }
    }

    &__subtitle {
      margin-top: 35px;
      font-size: 17px;
      line-height: 30/17*1em;
    }
  }
}

@include respond-up('medium') {
  .recall-modal {
    &__info {
      &_popup {
        .recall-modal {
          &__title {
            font-size: 30px;
            line-height: 42/30*1em;
          }
        }
      }
    }

    &__wrap {
      display: flex;
      justify-content: center;
    }
  }
}

@include respond('medium') {
  .recall-modal {
    &__info {
      flex: 0 0 309px;
      padding: 8px 82px 8px 0;

      &_popup {
        padding: 40px 238px 34px 40px;

        .recall-modal {
          &__image {
            object-fit: cover;
          }

          &__label {
            margin-bottom: 30px;
          }

          &__title {
            margin-bottom: 43px;
          }
        }
      }
    }

    &__subtitle {
      margin-top: 25px;
    }

    &__form {
      flex: 0 0 380/768*100vw;

      &_success {
        padding: 100/1500*100vw 0;
      }
    }
  }
}

@include respond-down('medium') {
  .recall-modal {
    &__subtitle {
      font-size: 15px;
      line-height: 24/15*1em;
    }

    &__wrap {
      &_popup {
        flex-direction: column;
      }
    }
  }
}

@include respond-down('small') {
  .recall-modal {
    &__subtitle {
      margin-top: 15px;
    }

    &__form {
      margin-top: 30px;

      &_success {
        padding: 75/375*100vw 0;
      }
    }

    &__wrap {
      &_popup {
        height: 100vh;
        .recall-modal {
          &__form {
            margin-top: 0;
            height: 60%;
          }
        }
      }
    }

    &__info {
      &_popup {
        padding: 40px 50px 26px 23px;
        height: 40%;
        .recall-modal {
          &__image {
            object-fit: cover;
          }

          &__label {
            margin-bottom: 17px;
          }

          &__title {
            font-size: 22px;
            line-height: 31/22*1em;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}