.teamDialog {
  padding: 36px;
  display: flex;
  flex-direction: column;
  max-width:774px;
  border:none;
  &::backdrop{
    background: rgba(33, 33, 33, 0.64)
  }
  &__close{
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
}

.teamDialogPerson {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;

  &__image {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    background: #ccc;
    object-position: center 0px;
    flex-shrink: 0;
  }

  &__name {
    font-size: 19px;
    line-height: 19px;
    color: #212121;
    font-weight: 400;
    margin-bottom: 6px;
  }

  &__position {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #7F7F7F;

  }

  &__caption {
    max-width: 230px;

  }
}

.teamDialogAboutPerson {
  margin-bottom: 48px;
  &__title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 16px;
    color: #212121;
    font-weight: 500;
  }

  &__description {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #7F7F7F;
  }
}

.teamDialogPersonInfo {
  display: flex;
  gap: 48px;
  flex-wrap:wrap;
  &__title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 16px;
    color: #212121;
    font-weight: 500;
  }

  &__content {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #7F7F7F;
  }

}