
* {
  box-sizing: border-box;

}

button, input, textarea {
  -webkit-tap-highlight-color: transparent;
}

input {
  outline: none;
  -webkit-appearance: none;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: none;
  resize: none;
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 500;
  color: #7F7F94;
  font-size: 16px;
  line-height: 24px;
}

.br12 {
  border-radius: 12px;
}

.rootComponents {
  &__body {
    margin-top: 100px;
    --maxWidthRightColumn: 430px;
    --gap: 64px;
    display: flex;
    gap: var(--gap);
    padding-bottom: 150px;
  }
}

.cartInfo {
  width: 100%;
  max-width: var(--maxWidthRightColumn);
  padding: 32px;
  background: #fff;
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 500;
  color: #000000;

  &__title {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 28px;

  }

  &__section {
    margin-bottom: 32px;
  }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-family: 'TT Norms';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;

      &:last-child {
        font-size: 26px;
        line-height: 34px;
      }
    }
  }

  &__btnConfirm {
    transition: 0.3s ease all;
    margin-top: 32px;
    padding: 20px;
    background: #E73C5F;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    &:hover {
      background: #f8466a;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 32px;
  }
}

.table {
  width: calc(100% - var(--gap) - var(--maxWidthRightColumn));

  &__btnAdd {
    transition: 0.3s ease all;
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 20px;
    background: #2A315C;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    gap: 12px;

    svg {
      path, rect {
        transition: 0.3s ease all;
      }
    }

    &:hover {
      background: #282F5A;
      color: #E73C5F;

      &:hover {
        svg {
          path, rect {
            fill: #E73C5F;
          }
        }
      }
    }
  }

  &__header, &__row {


    --colimn1: 170px;
    --colimn2: 172px;
    --colimn3: 130px;
    --colimn4: 20%;
    display: grid;
    grid-template-columns: var(--colimn1) var(--colimn2) var(--colimn3) var(--colimn4);
    justify-content: space-between;
    align-items: center;


  }

  &__header {
    padding-bottom: 8px;
    color: #7F7F94;
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 2px solid #393F67;

  }

  &__row {
    padding: 32px 0;
    border-bottom: 2px solid #393F67;
    position: relative;

    &__remove {
      background: transparent;
      position: absolute;
      display: flex;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        path {
          transition: 0.3s ease all;
        }
      }

      &:hover {
        svg {
          path {
            fill: #FFF
          }
        }
      }
    }

    &__header {
      display: none;
    }

    &__title {
      color: #7F7F94;
      font-family: 'TT Norms';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    &__price {
      position: relative;

      input {
        border-width: 1px !important;
        border-color: transparent !important;

        &:hover {
          border-color: #7F7F94 !important;
        }

        &:focus {
          border-color: #393F67 !important;
        }


      }

      .table__row__title, .inputControl__error {
        position: absolute;
        top: 100%;
        display: block;
      }
    }

    &__total {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }

    &__product {
      img {
        width: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }
}

.counter {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #393F67;
  height: 56px;

  &__input {
    width: 40px;
    text-align: center;
    color: #fff;
    font-weight: 500;
  }

  &__btn {
    transition: 0.3s ease all;
    background: transparent;
    display: flex;
    height: 30px;
    width: 30px;
    align-content: center;
    justify-content: center;
    align-items: center;

    svg {
      path, rect {
        transition: 0.3s ease all;
      }
    }

    &:hover {
      svg {
        path, rect {
          fill: #E73C5F;
        }
      }
    }
  }
}

@media screen and (max-width: 1559px) {
  .rootComponents {
    &__body {
      --maxWidthRightColumn: 350px
    }

  }
}

@media screen and (max-width: 1439px) {
  .rootComponents {
    &__body {
      margin-top: 100px;
      --maxWidthRightColumn: 100%;
      --gap: 64px;
      flex-wrap: wrap;
    }
  }
  .table {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .rootComponents {
    &__body {
      margin-top: 0;
      --gap: 80px;
      padding-bottom: 80px;
    }
  }

  .table {
    &__header {
      display: none;
    }

    &__row {
      display: flex;
      flex-direction: column;
      align-content: center;
      row-gap: 24px;

      &__remove {
        top: 60px;
        right: 15px;
      }

      &__counter, &__price, &__total {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 12px;

      }

      &__price {
        input {
          max-width: 130px;
        }
      }

      &__counter {
        margin-top: 22px;
      }

      &__header {
        font-family: 'TT Norms';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        display: block;
      }
    }

    &__btnAdd {
      padding: 20px 14.5px;
      margin-bottom: 0;
      margin-top: 24px;
    }
  }
  .formCart {
    padding: 20px;


  }
  .cartInfo {
    &__title {
      font-size: 22px;
      line-height: 30px;
    }

    &__section {
      margin-top: 24px;
    }

    &__btnConfirm {
      padding: 16px 20px;
      margin-top: 26px;

    }

    &__total {
      margin-top: 26px;
      font-size: 18px;
      line-height: 26px;

      &:last-child {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}

.sending span {
  -webkit-animation-name: blink;
  animation-name: blink;
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.sending span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sending span:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
