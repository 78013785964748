.article {
  &__title {

  }

  &__head-wrapper {

  }

  &__date-and-link {
    display: flex;
    align-items: center;
  }

  &__date {
    position: relative;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $primary-color;

    &::after {
      content: '|';
      position: absolute;
      right: 0;
      top: 0;
      color: $text-grey-color;
      opacity: 0.6;
    }
  }

  &__image {
    width: 100%;

  }

  &__text-container {

  }

  &__another-article {

  }

  &__item {
    border-bottom: 1px dashed $text-grey-color;

    &:last-child {
      border-bottom: none;
    }

  }

  &__date-container {
    display: flex;
    flex-direction: column;
  }

  &__another-date {
    color: $primary-color;
    font-weight: 500;


  }

  &__image-wrapper{
    background: {
      position: center;
      repeat: no-repeat;
      size: cover;
    };
  }

  &__short-text {

  }

  &__description {

  }
}

@include respond-up('large') {
  .article {
    padding-bottom: 125px;

    &__title {
      font-size: 60/1675*100vw;
      line-height: 72/1675*100vw;
      margin-bottom: 80/1675*100vw;
    }

    &__image-wrapper{
      height: 672/1500*100vw;
      width: 100%;
    }

    &__date-and-link {
      margin-bottom: 40/1675*100vw;
    }

    &__date {
      padding-right: 60px;
    }

    &__share {
      padding-left: 60px;
    }

    &__image {
      margin-bottom: 120px;
      max-height: 700/1675*100vw;
    }

    &__text-container {
      display: flex;
      justify-content: space-between;
    }

    &__another-article {
      flex: 0 0 30%;
    }

    &__another-date {
      margin-bottom: 28px;

    }

    &__content {
      flex: 0 0 60%;
    }

    &__short-text {
      font-size: 21px;
      line-height: 33px;
      margin-bottom: 90px;
      display: inline-block;
    }

    &__description {

    }

    &__name {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 25px;
      font-weight: 500;
    }

    &__item {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }
}

@include respond-down('medium') {
  .article {
    padding-bottom: 60px;


    &__title {
      font-size: 40/768*100vw;
      line-height: 52/768*100vw;
      margin-bottom: 40/768*100vw;
    }

    &__date-and-link {
      margin-bottom: 29/768*100vw;
    }

    &__date {
      padding-right: 50px;
    }

    &__image-wrapper{
      width: 100%;
      height: 387/768*100vw;
    }

    &__share {
      padding-left: 50px;

    }

    &__image {
      margin-bottom: 40px;
    }

    &__text-container {
      display: flex;
      flex-direction: column;
    }

    &__another-article {
      padding-bottom: 25px;
      border-bottom: 1px dashed $text-grey-color;
    }

    &__another-date {
      margin-bottom: 15px;

    }

    &__content {
      border-bottom: 1px dashed $text-grey-color;
      padding-bottom: 60px;
      margin-bottom: 25px;
    }

    &__short-text {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 40px;
      display: inline-block;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__name {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 25px;
      font-weight: 500;
    }

    &__item {
      margin-bottom: 35px;
      flex: 0 0 50%;
      border: none;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }
}


@include respond-down('small') {
  .article {
    padding-bottom: 40px;


    &__title {
      font-size: 30/375*100vw;
      line-height: 42/375*100vw;
      margin-bottom: 28/375*100vw;
    }

    &__date-and-link {
      margin-bottom: 20/375*100vw;
      display: flex;
      flex-direction: column;
      align-items: normal;
    }

    &__date {
      padding: 0;
      margin-bottom: 10px;

      &:after {
        display: none;
      }
    }

    &__share {
      padding: 0;
      margin-bottom: 20px;

    }

    &__image {
      margin-bottom: 30px;
    }

    &__text-container {
      display: flex;
      flex-direction: column;
    }

    &__another-article {
      padding-bottom: 25px;
      border-bottom: 1px dashed $text-grey-color;
    }

    &__another-date {
      margin-bottom: 28px;

    }

    &__content {
      border-bottom: 1px dashed $text-grey-color;
      padding-bottom: 50px;
      margin-bottom: 0;
      border-bottom: none;
    }

    &__short-text {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 30px;
      display: inline-block;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__name {
      font-size: 15px;
      line-height: 25px;
      margin-bottom: 20px;
      font-weight: 500;
    }

    &__item {
      margin-bottom: 35px;
      flex: 0 0 50%;
      border: none;

      &:last-child {
        margin-bottom: 0;
      }

    }

    &__another-date {
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 15px;
    }

    &__list {
      flex-direction: column;
    }

    &__another-article {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &__image{
      height: 100%;
    }

    &__item {
      border-bottom: 1px dashed $text-grey-color;
      margin-bottom: 20px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
    }

  }
}