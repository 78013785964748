.certificate__success{
  display: flex;align-items: center;
  justify-content: center;
}
.block__success{
  margin-top: 120px;
  border-radius: 12px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;align-items: center;
  gap: 24px;
  &__img{
    height: 167px;
    width: 167px;

  }
  &__btnBack{
    margin-top: 24px;
    background: #E73C5F;
    border-radius: 12px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    max-width: 368px;
    line-height: 22px;
    font-size: 16px;
    color: #FFFFFF;;
  }
  &__thx{
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  &__text{
    text-align: center;;
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;

  }
  &__footer{
    text-align: center;
    font-family: 'TT Norms';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #7F7F94;
    margin-top: 24px;
    a{

    }
  }
}
@media screen and (max-width: 767px) {
  .certificate__success{
  }
  .block__success{
    margin-top: 20px;

    &__img{
      height: 110px;
      width: 110px;

    }
    &__btnBack{
      margin-top: 0px;
      height: 54px;
    }
    &__thx{
      font-size: 26px;
      line-height: 34px;
    }
    &__text{
      font-size: 16px;
      line-height: 24px;
    }
    &__footer{
      margin-top: 0;
      a{
        display: block;
        width: 100%;
      }
    }
  }
}