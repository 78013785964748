.social {
  &__wrapper {
    margin: 0 -9px;
    display: flex;
  }

  &__circle {
    margin: 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $secondary-color;
    transition: $default-transition;

    &:hover {
      background-color: $secondary-color;
    }

    &_vk {
      svg {
        margin-top: 2px;
        margin-right: 1px;
      }
    }
  }
}