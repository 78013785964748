

.card {
    --paddingLR: 1.406vw;
  padding: 27px var(--paddingLR) 50px var(--paddingLR);
  border-radius: 12px;
  background: #262D5B;
  width: 100%;
  transition: 1s ease height;

  &__title {
    font-family: 'TT Norms';
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    text-align: center;

  }

  hr {
    border: none;
    border-bottom: 1px solid #393F67;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 767px) {
    .card {
        padding: 20px 20px;

        hr {
            margin-left: -20px;
            margin-right: -20px;
        }

        &__title {
            font-size: 22px;
            line-height: 30px;
            max-width: 225px;
            margin: auto;

        }

        hr {
            margin-top: 20px;
            margin-bottom: 20px;
      }
  }
}

