body {
  background-color: $background-color;
  font-size: $body-font-size;
  font-family: $font;
  color: #fff;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &._fixed{
    overflow: hidden;
    height: 100%;
  }
}

input, button, form {
  font-family: $font;
}

@include respond-up("large") {
  .container {
    padding: 0 $indent-large;
  }
}

@include respond("medium") {
  .container {
    padding: 0 $indent-medium;
  }
}

@include respond-down("small") {
  .container {
    padding: 0 $indent-small;
  }
}