
.inputControl {
  input {
    transition: 0.3s ease all;
    height: 56px;
    border-radius: 12px;
    border: 2px solid #DFE2E3;
    padding: 16px 20px;
    width: 100%;
  font-size: 16px;
  line-height: 24px;
    color: #000000;
    background: #FFFFFF;

    &::placeholder {
      color: #7F7F94;
        font-size: 16px;
        line-height: 24px;
    }
      &:hover{
          border-color: #7F7F94;
      }
      &:focus{
          border-color: #393F67;
      }
  }
    &__error{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #E1002E;
    }

  &--error {
    input {
      border-color: #E1002E !important;
        &:focus{
            border-color: #E1002E !important;
        }
        &:hover{
            border-color: #E1002E !important;
        }
    }
  }

}


