$max-row-width: 1246;
$grid-columns: 12;
$grid: (
      x-small: (
              from: 0,
              to: 374,
              gutter: 12,
              spacer: 16
      ),
      small: (
              from: 375,
              to: 767,
              gutter: 14,
              spacer: 18
      ),
      medium: (
              from: 768,
              to: 1245,
              gutter: 22,
              spacer: 39
      ),
      large: (
              from: 1246,
              to: 1439,
              gutter: 46
      ),
      x-large: (
              from: 1440,
              gutter: 46
      ),
);

$body-font-size: 16px;
$font: "TT Norms", sans-serif;
$h-font: "TT Norms", sans-serif;

$t: transparent;

$primary-color: #009CE0;
$secondary-color: #E73C5F;
$background-color: #181F4E;
$text-color: #7F7F7F;
$text-grey-color: #D8D8D8;
$text-color-opacity: rgba(255,255,255, 0.4);

$text-color-transparent: rgba(255, 255, 255, .6);
$table-head-bg: #EBF0F5;
$table-border-color: rgba(94, 110, 117, .2);
$delimiter-yellow: #FFC213;
$alert-color: #b50000;

$default-transition: all 0.4s ease-in-out;

// отступы для контейнера
$indent-large: 150/1500*100vw;
$indent-medium: 39/768*100vw;
$indent-small: 17/375*100vw;
