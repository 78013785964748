.book-form {
  background-color: #5526AB;
  input{
    font-weight: initial !important;
    color: $text-color-transparent !important;
    border:solid $text-color-transparent !important;
    border-width: 0 0 1px 0 !important;
    &::placeholder{
      color: $text-color-transparent !important;
    }
  }
  .floating-field label{
    color: $text-color-transparent !important;
  }

  &__action {
    display: flex;
    align-items: center;
  }

  &__policy {
    color: $text-color-transparent;
  }


  &__policy-link {
    border-bottom: 1px solid $t;
    color: $secondary-color;
    transition: border-bottom-color .4s;

    &:hover {
      border-bottom-color: $secondary-color;
    }
  }

  &__success {
    color: $text-color-transparent !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  &__success-title {
    text-align: center;
    color: $text-color-transparent !important;
  }

  &__success-subtitle {
    margin-top: 20px;
    text-align: center;
    max-width: 293px;
    font-size: 15px;
    color: $text-color-transparent !important;


    line-height: 30/15*1em;
  }

  &__title {
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #FFF;
  }

  &__subtitle {
    font-weight: normal;
    color: #7A7A7A;
  }
}

@include respond-up('large') {
  .book-form {
    &__data {
      padding: 68/1500/2*100vw 72/1500/2*100vw 66/1500/2*100vw;

      &_popup {
        padding: 60px 69px 60px 60px;

        .book-form {
          &__fields {
            margin-bottom: 30px;
          }

          &__field {
            &:not(:last-child) {
              margin-bottom: 40px;
            }

            input {
              height: 42px;
            }
           }
        }
      }
    }

    &__fields {
      margin-bottom: 42px;
    }

    &__field {
      &:not(:last-child) {
        margin-bottom: 52px;
      }
    }

    &__policy {
      margin-left: 44px;
      font-size: 14px;
      line-height: 21/14*1em;
      min-width: 215px    ;
    }

    &__success-title {
      margin-top: 50px;
      font-size: 25px;
      line-height: 24/25*1em;
    }

    &__title {
      margin-bottom: 10px;
    }

    &__subtitle {
      margin-bottom: 40px;
    }
  }
}

@include respond-up('medium') {
  .book-form {
    &__title {
      font-size: 26px;
      line-height: 36/26*1em;
    }

    &__subtitle {
      font-size: 17px;
      line-height: 24/17*1em;
    }
  }
}

@include respond('medium') {
  .book-form {
    &__data {
      padding: 36/768*100vw;

      &_popup {
        .book-form {
          &__action {
            flex-direction: column;
          }

          &__button {
            margin-bottom: 22px;
          }
        }
      }
    }

    &__policy {
      margin-left: 15px;
    }

    &__title {
      margin-bottom: 17px;
    }

    &__subtitle {
      margin-bottom: 43px;
    }
  }
}

@include respond-down('medium') {
  .book-form {
    &__fields {
      margin-bottom: 24px;
    }

    &__field {
      &:not(:last-child) {
        margin-bottom: 36px;
      }
    }

    &__policy {
      font-size: 13px;
      line-height: 20/13*1em;
    }

    &__success-icon {
      svg {
        height: 68px;
        width: 68px;
      }
    }

    &__success-title {
      margin-top: 40px;
      font-size: 23px;
      line-height: 24/23*1em;
    }
  }
}

@include respond-down('small') {
  .book-form {
    &__data {
      padding: 25/375*100vw 25/375*100vw 32/375*100vw;

      &_popup {
        .book-form {
          &__action {
            flex-direction: row;
          }

          &__policy {
            margin-top: 0;
          }
        }
      }
    }

    &__action {
      flex-direction: column;
    }

    &__policy {
      margin-top: 25px;
      text-align: center;
    }

    &__title {
      font-size: 22px;
      line-height: 31/22*1em;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 15px;
      line-height: 21/15*1em;
      margin-bottom: 36px;
    }
  }
}