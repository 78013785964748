.share {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__title {
    margin-right: 13px;
    font-size: 13px;
    line-height: 20px;
    color: rgba(255,255,255,0.42);
  }

  &__links {
    .ya-share2__link {
      transition: all 0.4s;

      .ya-share2__badge {
        background-color: transparent;
      }
    }
  }
}

@include respond-down('medium') {
  .share {
    &__title {
      font-size: 13px;
      margin-right: 22px;
    }
  }
}