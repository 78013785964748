.prices {
  &__top {

  }

  &__nav {
    transform: translateY(30px);
    opacity: 0;
    transition: {
      delay: 1.4s;
      property: opacity, transform;
      duration: 1.14s;
    }
  }

  &__main {

  }

  &__packets {
    transform: translateY(50px);
    opacity: 0;
    transition: {
      delay: .7s;
      duration: 1.35s;
      property: opacity, transform;
    };
  }

  &__name-wrap {
    position: relative;
  }

  &__name {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    font-weight: bold;
    font-size: 21px;
    line-height: 31/21*1em;
    text-align: center;
    text-transform: uppercase;
    pointer-events: none;
    z-index: 100;
  }

  &__packet {
    position: relative;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;

    &:last-child {
      margin-bottom: 0;
    }

    &::before,
    &::after {
      content: '';
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .packet {
      &__name {
        position: sticky;
      }
    }
  }

  &.animate{
    .prices{
      &__nav{
        transform: translateY(0);
        opacity: 1;
      }
      &__packets{
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

@include respond-up('large') {
  .prices {
    &__top {
      margin-top: 100px;
    }

    &__main {
      padding-top: 100px;
    }

    &__packet {
      max-width: 100%;
      margin-bottom: 80px;

      &::before,
      &::after {
        flex: 0 0 $indent-large;
      }
    }
  }
}

@include respond('medium') {
  .prices {
    &__top {
      margin-top: 60px;
    }

    &__main {
      padding-top: 70px;
    }

    &__packet {
      margin-bottom: 70px;

      &::before,
      &::after {
        flex: 0 0 $indent-medium;
      }
    }
  }
}

@include respond-down('medium') {
  .prices {

  }
}

@include respond-down('small') {
  .prices {
    &__top {
      margin-top: 40px;
    }

    &__main {
      padding-top: 52px;
    }

    &__packet {
      margin-bottom: 40px;

      &::before,
      &::after {
        flex: 0 0 $indent-small;
      }
    }
  }
}