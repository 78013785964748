$default-input-height: 37px;
$inputs: 'input[type="text"], input[type="search"], input[type="password"], input[type="date"]';

.button, button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: $default-transition;
  display: inline-block;
  border: none;
  background: $primary-color;
  font-size: 16px;
  cursor: pointer;

  &.expand, &.wide {
    width: 100%;
  }

  &.height {
    height: $default-input-height;
  }
}
input[type="date"]{
  & ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

form:not(.formCart,.ticketTotals) {
  label {
    display: block;
    line-height: 1em;
    letter-spacing: 0;
    color: $text-color;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    font-size: 13px;
    color: $alert-color;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    display: block;
    width: 100%;
    margin: 0;
    border: solid #000;
    border-width: 0 0 1px 0;
    border-radius: 0;
    color: $text-color;
    background-color: $t;
    box-shadow: none;
    appearance: none;
    font-family: $font;

    &::placeholder {
      color: $text-color;
      line-height: 1em;
    }
  }

  #{$inputs}, select {
    height: $default-input-height;
  }

  textarea {
    padding: 5px 10px;
  }
}

@include respond-up('large') {
  form:not(.formCart,.ticketTotals) {
    label {
      font-size: 14px;
    }

    #{$inputs}, textarea, select {
      height: 56px;
      font-size: 20px;

      &::placeholder {
        font-size: 20px;
      }
    }
  }
}

@include respond-down('medium') {
  form {
    label {
      font-size: 12px;
    }

    #{$inputs}, textarea, select {
      height: 45px;
      font-size: 17px;

      &::placeholder {
        font-size: 17px;
      }
    }
  }
}