.product-page{

  &__title{
    transform: translateX(57px);
    opacity: 0;
    transition: {
      property: opacity, transform;
      duration: 1.5s;
      delay: .7s;
    };
  }

  &__top{
    position: relative;

    &.animate{
      .product-page{
        &__description{
          opacity: 1;
          transform: translateX(0);
        }
        &__indicators-wrapper{
          opacity: 1;
          transform: translateY(0);
        }
        &__image-wrapper{
          opacity: 1;
          transform: translateY(0);
        }
        &__title{
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    &:not(.animate){
      .indicators__progress{
        width: 0 !important;
      }
    }
  }

  &__description-title-wrapper{
    display: flex;
    justify-content: space-between;
  }

  &__description-wrapper{
    &.animate {
      .product-page__description {
          opacity: 1;
          transform: translateX(0);
      }
    }
  }

  &__description{
    color: #A2A5B8;
    font-size: 21px;
    line-height: 35/21*1em;
    transform: translateX(60px);
    opacity: 0;
    transition: {
      property: opacity, transform;
      delay: 1.42s;
      duration: 1.5s;
    };

    &_main {
      transition-delay: 0.2s;
    }
  }

  &__indicators-wrapper{
    flex: 0 0 auto;
    opacity: 0;
    transform: translateY(30px);
    transition: {
      property: opacity, transform;
      duration: 1.26s;
      delay: 2s;
    };
  }

  &__alt{
    display: none;
  }

  &__content{
    //background-color: white;
  }

  &__image-wrapper{
    position: absolute;
    opacity: 0;
    transform: translateY(40px);
    transition: {
      property: opacity, transform;
      duration: 1.26s;
      delay: 2s;
    };
  }

  &__image{
    height: 672/1500*100vw;
    background: {
      position: center;
      repeat: no-repeat;
      size: cover;
    };
  }

  &__features-map{
    position: relative;
  }

  &__advantage-wrapper{
    display: flex;
  }

  &__advantages{
  }

  &__plate{
  }

  &__additional{
    width: 100%;
  }

  &__slider-wrap {
    overflow: hidden;
  }

  &__slider{
    transform: translateX(150px);
    transition: {
      duration: 1.1s;
      property: transform;
      timing-function: cubic-bezier(0.52, 0.15, 0.25, 0.8);
    };

    &.animate{
      transform: translateX(0);
    }
  }

  &__other{

  }

  &__other-title{
    font-weight: bold;
    font-size: 50px;
    color: black;
    text-transform: uppercase;
  }

  &__other-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__other-item {
  }

  &__trigger{
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    &_top{
      top: -30%;
    }
    &_bottom{
      bottom: -70%;
    }
  }
}

@include respond-up('large'){
  .product-page{
    &__description-title-wrapper{
      padding: 68px 0 86px;
    }

    &__description{
      font-size: 21px;
      line-height: 35/21*1em;
      flex: 0 0 572/1500*100vw;

      &_main {
        padding: 0 149/1500*100vw 100px 0;
      }
    }

    &__indicators-wrapper{
      margin-right: 89/1500*100vw;
    }

    &__image-wrapper{
      left: 150/1500*100vw;
      right: 150/1500*100vw;;
    }

    &__image{
      width: 100%;
      height: 672/1500*100vw;
    }

    &__advantage-wrapper{
      padding-bottom: 31/1500*100vw;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;
    }

    &__advantages{
      flex: 0 1 608px;
      margin-right: 30px;
    }

    &__plate-wrapper{
      flex: 0 0 418px;
      position: relative;
    }

    &__plate{
      top: 150px;
      position: sticky;
    }

    &__additional{
      padding-bottom: 139/1500*100vw;
    }

    &__slider{
      padding: 0 $indent-large 110/1500*100vw;
    }

    &__other-title{
      font-size: 50px;
      line-height: 60/50*1em;
      margin-bottom: 70px;
    }

    &__other-list {
      margin: 0 -90/1500*100vw;
    }

    &__other-item {
      padding: 0 90/1500*100vw;
    }
  }
}

@include respond-up('medium'){
  .product-page{
    &__image{
      position: absolute;
    }

    &__other-item {
      flex: 0 0 50%;
    }
  }
}

@include respond('medium'){
  .product-page{
    &__description-title-wrapper{
      padding: 30px 0 70px;
    }

    &__description{
      font-size: 18px;
      line-height: 28/18*1em;
      flex: 0 0 336/768*100vw;

      &_main {
        padding-bottom: 40/768*100vw;
      }
    }

    &__image-wrapper{
      left: 39/768*100vw;
      right: 39/768*100vw;
    }

    &__image{
      width: 100%;
      height: 387/768*100vw;
    }

    &__advantage-wrapper{
      padding-bottom: 20/768*100vw;
    }

    &__plate-wrapper{
      margin-bottom: 50/768*100vw;
    }

    &__additional{
      padding-bottom: 70/768*100vw;
    }

    &__slider{
      padding: 0 $indent-medium 70/768*100vw;
    }

    &__other-title{
      font-size: 25px;
      line-height: 1em;
      margin-bottom: 40px;
    }

    &__other-list {
      margin: 0 -30/768*100vw;
    }

    &__other-item {
      padding: 0 30/768*100vw;
    }
  }
}

@include respond-down('medium'){
  .product-page{
    &__advantage-wrapper{
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
    &__advantages{
      flex: 0 0 100%;
      order: 5;
    }

    &__plate-wrapper{
      order: 2;
      flex: 0 0 100%;
    }

    &__plate{
      width: 100%;
    }
  }
}

@include respond-down('small'){
  .product-page{
    &__description-title-wrapper{
      flex-wrap: wrap;
      padding: 18px 0 40px;
    }

    &__description{
      font-size: 16px;
      line-height: 26/16*1em;
      flex: 0 0 100%;

      &_main {
        padding-bottom: 30/375*100vw;
      }
    }

    &__indicators-wrapper{
      margin-top: 40px;
    }

    &__image-wrapper{
      left: 0;
      right: 0;
      padding: 0 17/375*100vw;
      overflow: auto;
      height: 387/375*100vw;
      display: flex;

      &::after{
        content: '';
        position: relative;
        width: 17/375*100vw;
        flex: 0 0 17/375*100vw;;
        height: 100%;
      }
    }

    &__image{
      position: relative;
      height: 100%;
      width: 690/375*100vw;
      flex: 0 0 690/375*100vw;;
    }

    &__advantage-wrapper{
      padding-bottom: 12/375*100vw;
    }

    &__plate-wrapper{
      margin-bottom: 40/375*100vw;
    }

    &__additional{
      padding-bottom: 40/375*100vw;
    }

    &__slider{
      padding: 0 $indent-small 40/375*100vw;
    }

    &__other-title{
      font-size: 23px;
      line-height: 1em;
      margin-bottom: 25px;
    }

    &__other-item{
      flex: 0 0 100%;
    }
  }
}