.title {
  text-transform: uppercase;
  font-weight: bold;
  -webkit-text-stroke-color: white;
}

@include respond-up('large') {
  .title {
    font-size: 100/1500*100vw;
    line-height: 110/100*1em;
    -webkit-text-stroke-width: 2px;

    &_small {
      font-size: 50/1500*100vw;
      line-height: 60/50*1em;
    }
  }
}

@include respond('medium') {
  .title {
    font-size: 60/768*100vw;
    line-height: 70/60*1em;

    &_small {
      font-size: 35/768*100vw;
      line-height: 45/35*1em;
    }
  }
}

@include respond-down('medium') {
  .title {
    -webkit-text-stroke-width: 1px;
  }
}

@include respond-down('small') {
  .title {
    font-size: 35/375*100vw;
    line-height: 42/35*1em;

    &_small {
      font-size: 30/375*100vw;
      line-height: 40/30*1em;
    }
  }
}